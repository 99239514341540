import { Form, Input, FormInstance, DatePicker, Typography } from 'antd';
import { KanhaBatch } from '../../../../../shared/KanhaInviteTypes';
import { useTranslation } from 'react-i18next';
import { DATE_DD_MMM_YYYY } from '../../../../../shared/Constants';
import moment from 'moment';
import dayjs, { extend } from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';
import { useExamModuleIds } from '../../../../../hooks/useExamModuleIds';
import TextArea from 'antd/es/input/TextArea';
const { Text } = Typography;
import { useAddorEditKanhaBatch } from './useAddorEditKanhaBatch';

extend(dayjsUTC);

interface KanhaInviteFormProps {
	form: FormInstance;
	kanhaforminterface?: KanhaBatch;
}

const SectionHeader = ({
	heading,
	description,
}: {
	heading: string;
	description?: string;
}) => {
	return (
		<div className="flex flex-col mb-[8px]">
			<h2>{heading}</h2>
			<Text className="mb-[8px]" type="secondary">
				{description}
			</Text>
		</div>
	);
};

export const KanhaBatchDetailsForm: React.FC<KanhaInviteFormProps> = ({
	form,
}: KanhaInviteFormProps) => {
	const { t } = useTranslation();
	const { kanhaBatchId } = useExamModuleIds();
	const { submitButtonDisabled } = useAddorEditKanhaBatch();
	return (
		<Form
			id="kanhabatchform"
			name="kanhaforminterface"
			layout="horizontal"
			size="middle"
			labelCol={{ span: 24 }}
			initialValues={{ remember: true }}
			autoComplete="off"
			form={form}
		>
			<SectionHeader
				heading={
					kanhaBatchId
						? submitButtonDisabled
							? t('viewKanhaBatch')
							: t('editKanhaBatch')
						: t('addKanhaBatch')
				}
			/>
			<div className="flex flex-col md:flex-row justify-between">
				<div className="flex flex-col md:mb-0">
					<div className="flex flex-wrap gap-4">
						<div className="w-full md:w-1/3">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('batchName')}
									</span>
								}
								name="name"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
							>
								<Input
									size="large"
									maxLength={50}
									disabled={submitButtonDisabled}
								/>
							</Form.Item>
						</div>

						<div className="w-full md:w-1/3">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('batchStartDate')}
									</span>
								}
								name="startDate"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
							>
								<DatePicker
									className="w-full"
									size="large"
									placeholder=""
									format={DATE_DD_MMM_YYYY}
									disabled={submitButtonDisabled}
									disabledDate={(current) =>
										current && current < moment().endOf('day')
									}
									onChange={(date) => {
										const endDate = form.getFieldValue(
											'endDate'
										) as dayjs.Dayjs;
										if (date && endDate && date.isAfter(endDate, 'day')) {
											form.setFieldsValue({ endDate: undefined });
										}
									}}
								/>
							</Form.Item>
						</div>

						<div className="w-full md:w-1/3">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('batchEndDate')}
									</span>
								}
								name="endDate"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
								]}
								dependencies={['startDate']}
							>
								<DatePicker
									className="w-full"
									size="large"
									placeholder=""
									format={DATE_DD_MMM_YYYY}
									disabled={submitButtonDisabled}
									disabledDate={(current: dayjs.Dayjs) => {
										const startDate = form.getFieldValue(
											'startDate'
										) as dayjs.Dayjs;
										if (!startDate) return true;
										return (
											current &&
											current.isBefore(dayjs(startDate).add(1, 'day'), 'day')
										);
									}}
								/>
							</Form.Item>
						</div>

						<div className="w-full md:w-1/3">
							<Form.Item
								className="!mb-2"
								label={
									<span className="font-bold text-dark-gray">
										{t('batchDescription')}
									</span>
								}
								name="description"
							>
								<TextArea
									size="large"
									maxLength={500}
									showCount
									disabled={submitButtonDisabled}
								/>
							</Form.Item>
						</div>
					</div>
				</div>
			</div>
		</Form>
	);
};
