import {
	DownloadCSVReportResponse,
	ImportCSVReportRequest,
	SendEmailRequest,
} from '../shared/ExamModuleTypes';
import {
	CreateKanhaBatchRequest,
	GetKanhaBatchCandidateCentersRequest,
	GetKanhaBatchCandidateCentersResponse,
	GetKanhaBatchCandidateDetailsRequest,
	GetKanhaBatchCandidateDetailsResponse,
	GetKanhaBatchCandidateZonesResponse,
	GetKanhaBatchesRequest,
	GetKanhaBatchesResponse,
	GetKanhaBatchRequest,
	GetKanhaBatchTemplatesRequest,
	GetKanhaBatchTemplatesResponse,
	KanhaBatch,
	KanhaBatchTemplate,
	UpdateKanhaBatchTemplateRequest,
} from '../shared/KanhaInviteTypes';
import { BooleanValue } from '../shared/VolunteerApplicationServiceTypes';
import { volunteerServiceClient } from './HTTPClient';

export const createKanhaBatch = async (
	request: CreateKanhaBatchRequest
): Promise<KanhaBatch> => {
	const response = await volunteerServiceClient.post<KanhaBatch>(
		'createKanhaBatch',
		request
	);
	return response.data;
};

export const updateKanhaBatch = async (
	request: CreateKanhaBatchRequest
): Promise<KanhaBatch> => {
	const response = await volunteerServiceClient.post<KanhaBatch>(
		'updateKanhaBatch',
		request
	);
	return response.data;
};

export const getKanhaBatchById = async (
	request: GetKanhaBatchRequest
): Promise<KanhaBatch> => {
	const response = await volunteerServiceClient.post<KanhaBatch>(
		'getKanhaBatchById',
		request
	);
	return response.data;
};

export const getKanhaBatches = async (
	request: GetKanhaBatchesRequest
): Promise<GetKanhaBatchesResponse> => {
	const response = await volunteerServiceClient.post<GetKanhaBatchesResponse>(
		'getKanhaBatches',
		request
	);
	return response.data;
};

export const updateKanhaBatchTemplate = async (
	request: UpdateKanhaBatchTemplateRequest
): Promise<KanhaBatchTemplate> => {
	const response = await volunteerServiceClient.post<KanhaBatchTemplate>(
		'updateKanhaBatchTemplate',
		request
	);
	return response.data;
};

export const getKanhaBatchTemplates = async (
	request: GetKanhaBatchTemplatesRequest
): Promise<GetKanhaBatchTemplatesResponse> => {
	const response =
		await volunteerServiceClient.post<GetKanhaBatchTemplatesResponse>(
			'getKanhaBatchTemplates',
			request
		);
	return response.data;
};

export const downloadKanhaBatchCandidateDetails = async (
	request: GetKanhaBatchCandidateDetailsRequest
): Promise<DownloadCSVReportResponse> => {
	const response = await volunteerServiceClient.post<DownloadCSVReportResponse>(
		'downloadKanhaBatchCandidateDetails',
		request
	);
	return response.data;
};

export const importEligibleCandidatesForKanhaBatch = async (
	request: ImportCSVReportRequest
): Promise<DownloadCSVReportResponse> => {
	const response = await volunteerServiceClient.post<DownloadCSVReportResponse>(
		'importEligibleCandidatesForKanhaBatch',
		request
	);
	return response.data;
};

export const getKanhaBatchCandidateDetails = async (
	request: GetKanhaBatchCandidateDetailsRequest
): Promise<GetKanhaBatchCandidateDetailsResponse> => {
	const response =
		await volunteerServiceClient.post<GetKanhaBatchCandidateDetailsResponse>(
			'getKanhaBatchCandidateDetails',
			request
		);
	return response.data;
};

export const sendKanhaBatchInviteEmail = async (
	request: SendEmailRequest
): Promise<BooleanValue> => {
	const response = await volunteerServiceClient.post<BooleanValue>(
		'sendKanhaBatchInviteEmail',
		request
	);
	return response.data;
};

export const downloadKanhaBatchCandidateDetailsForUploadingResult = async (
	request: GetKanhaBatchCandidateDetailsRequest
): Promise<DownloadCSVReportResponse> => {
	const response = await volunteerServiceClient.post<DownloadCSVReportResponse>(
		'downloadKanhaBatchCandidateDetailsForUploadingResult',
		request
	);
	return response.data;
};

export const uploadKanhaBatchResults = async (
	request: ImportCSVReportRequest
): Promise<DownloadCSVReportResponse> => {
	const response = await volunteerServiceClient.post<DownloadCSVReportResponse>(
		'uploadKanhaBatchResults',
		request
	);
	return response.data;
};

export const sendKanhaBatchResultEmail = async (
	request: SendEmailRequest
): Promise<BooleanValue> => {
	const response = await volunteerServiceClient.post<BooleanValue>(
		'sendKanhaBatchResultEmail',
		request
	);
	return response.data;
};

export const getCentersOfKanhaBatchCandidates = async (
	request: GetKanhaBatchCandidateCentersRequest
): Promise<GetKanhaBatchCandidateCentersResponse> => {
	const response =
		await volunteerServiceClient.post<GetKanhaBatchCandidateCentersResponse>(
			'getCentersOfKanhaBatchCandidates',
			request
		);
	return response.data;
};

export const getZonesOfKanhaBatchCandidates = async (
	request: GetKanhaBatchCandidateCentersRequest
): Promise<GetKanhaBatchCandidateZonesResponse> => {
	const response =
		await volunteerServiceClient.post<GetKanhaBatchCandidateZonesResponse>(
			'getZonesOfKanhaBatchCandidates',
			request
		);
	return response.data;
};
