import { useCallback } from 'react';
import {
	GetKanhaBatchCandidateCentersResponse,
	GetKanhaBatchCandidateZonesResponse,
	KanhaBatch,
	KanhaBatchCandidateCenter,
	KanhaBatchCandidateZone,
	KanhaBatchFilter,
} from '../../../../shared/KanhaInviteTypes';
import { Button, Form, Input, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	VALID_ABHYASI_ID_REGEX,
	VALID_NAME_REGEX,
} from '../../../../shared/Constants';
import { useToastMessage } from '../../../../hooks/useToastMessage';

interface FilterProps {
	value: KanhaBatchFilter;
	onFilterChange: (value: KanhaBatchFilter) => void;
	kanhaBatchById: KanhaBatch;
	kanhaCandidateCenters: GetKanhaBatchCandidateCentersResponse;
	kanhaCandidateZones: GetKanhaBatchCandidateZonesResponse;
	disableFilters: boolean;
}

interface KanhaEligibleCandidatesFilterFormType {
	srcmId?: string;
	name?: string;
	candidateCenterIds?: Array<number>;
	candidateZoneIds?: Array<number>;
}

const { Text } = Typography;

export const FilterKanhaEligibleCandidates = ({
	value,
	onFilterChange,
	kanhaBatchById,
	kanhaCandidateCenters,
	kanhaCandidateZones,
	disableFilters = false,
}: FilterProps) => {
	const { t } = useTranslation();
	const { showToastMessage } = useToastMessage();
	const [form] = Form.useForm<KanhaEligibleCandidatesFilterFormType>();
	const formValues = Form.useWatch<KanhaEligibleCandidatesFilterFormType>(
		[],
		form
	);

	const kanhaCandidateCenterOptions =
		kanhaCandidateCenters.kanhaBatchCandidateCenters.map(
			(item: KanhaBatchCandidateCenter) => ({
				value: item.centerId,
				label: item.centerName,
			})
		);

	const kanhaCandidateZoneOptions =
		kanhaCandidateZones.kanhaBatchCandidateZones.map(
			(item: KanhaBatchCandidateZone) => ({
				value: item.zoneId,
				label: item.zoneName,
			})
		);

	const handleFilterClick = useCallback(() => {
		form
			.validateFields()
			.then(() => {
				const filterValues: KanhaBatchFilter = {
					...formValues,
					srcmId: formValues?.srcmId,
					name: formValues?.name,
					centerIds: formValues?.candidateCenterIds,
					zoneIds: formValues?.candidateZoneIds,
				};
				const kanhaBatchFilters = { ...value, ...filterValues };
				onFilterChange(kanhaBatchFilters);
			})
			.catch(() => {
				showToastMessage('error', t('pleaseEnterValidData'));
			});
	}, [onFilterChange, value, formValues, form, showToastMessage, t]);

	const handleResetForm = useCallback(() => {
		form.resetFields();
		form.setFieldsValue({
			srcmId: undefined,
			name: undefined,
		});
		const filterValues: KanhaBatchFilter = {
			kanhaBatchId: kanhaBatchById.id,
		};
		onFilterChange(filterValues);
	}, [form, onFilterChange, kanhaBatchById.id]);

	return (
		<div className="flex flex-col">
			<Form
				name="kanhaEligibleCandidatesFilterForm"
				layout="horizontal"
				size="middle"
				labelCol={{ span: 24 }}
				wrapperCol={{ span: 24 }}
				autoComplete="off"
				initialValues={value}
				form={form}
				disabled={disableFilters}
			>
				<div className="flex flex-col md:mb-0">
					<div className="flex flex-col gap-4 md:flex-row">
						<div className="flex flex-col md:mb-0 w-full min-w-[170px]">
							<Text className="pl-1">{t('abhyasiId')}</Text>
							<Form.Item
								className="!mb-2"
								name={'srcmId'}
								rules={[
									{
										pattern: VALID_ABHYASI_ID_REGEX,
										message: <span>{t('invalidAbhyasiId')}</span>,
									},
								]}
							>
								<Input placeholder="Enter Abhyasi ID" />
							</Form.Item>
						</div>
						<div className="flex flex-col md:mb-0 w-full min-w-[170px]">
							<Text className="pl-1">{t('name')}</Text>
							<Form.Item
								className="!mb-2"
								name={'name'}
								rules={[
									{
										pattern: VALID_NAME_REGEX,
										message: <span>{t('specialCharactersNotAllowed')}</span>,
									},
								]}
							>
								<Input placeholder="Enter Name" />
							</Form.Item>
						</div>
						<div className="flex flex-col md:mb-0 w-full min-w-[170px]">
							<Text className="pl-1">{t('candidateCenter')}</Text>
							<Form.Item className="!mb-2" name={'candidateCenterIds'}>
								<Select
									showSearch
									options={kanhaCandidateCenterOptions}
									filterOption={(input, option) =>
										(option?.label ?? '')
											.toLowerCase()
											.includes(input.toLowerCase())
									}
									placeholder={t('searchCenter')}
								/>
							</Form.Item>
						</div>
						<div className="flex flex-col md:mb-0 w-full min-w-[170px]">
							<Text className="pl-1">{t('candidateZone')}</Text>
							<Form.Item className="!mb-2" name={'candidateZoneIds'}>
								<Select
									showSearch
									options={kanhaCandidateZoneOptions}
									filterOption={(input, option) =>
										(option?.label ?? '')
											.toLowerCase()
											.includes(input.toLowerCase())
									}
									placeholder={t('searchZone')}
								/>
							</Form.Item>
						</div>
					</div>
				</div>
			</Form>
			<div className="flex flex-row gap-2 md:mb-0">
				<div className="self-start">
					<Button
						className="bg-nav-blue hover:!bg-hover-nav-blue text-white"
						type="primary"
						onClick={handleFilterClick}
						onKeyDown={handleFilterClick}
					>
						{t('filter')}
					</Button>
				</div>
				<div className="self-start">
					<Button type="default" onClick={handleResetForm}>
						{t('clear')}
					</Button>
				</div>
			</div>
		</div>
	);
};
