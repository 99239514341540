import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCentersList } from './useCentersList';
import { tablePaginationConfiguration } from '../../../shared/Utils';
import { CenterTableColumns } from './CenterTableColumns';
import { CREATE_CENTER } from '../../../shared/Routes';
import { Button, Table } from 'antd';
import { useExamModuleIds } from '../../../hooks/useExamModuleIds';
import { FilterCenters } from './FilterCenters';

export const CentersList = () => {
	const { t } = useTranslation();
	const { navigateWithoutExamModuleIds } = useExamModuleIds();
	const {
		centersFilter,
		centersList,
		centersPaging,
		onCentersPageChange,
		onCentersFilterChange,
	} = useCentersList();
	const { examinationCenters, pagination } = centersList;
	const tablePaginationConfig = tablePaginationConfiguration(
		centersPaging,
		pagination,
		onCentersPageChange
	);
	const columns = CenterTableColumns();

	const handleCreateCenterClick = useCallback(() => {
		navigateWithoutExamModuleIds(CREATE_CENTER);
	}, [navigateWithoutExamModuleIds]);

	return (
		<>
			<div className="flex justify-between items-center mb-2.5">
				<h3>{t('centersList')}</h3>
				<Button
					className="bg-nav-blue hover:!bg-hover-nav-blue text-white !rounded-none"
					type="primary"
					size="large"
					onClick={handleCreateCenterClick}
				>
					{t('createCenter')}
				</Button>
			</div>
			<FilterCenters
				value={centersFilter}
				onFilterChange={onCentersFilterChange}
			/>
			<Table
				dataSource={examinationCenters}
				columns={columns}
				pagination={tablePaginationConfig}
				scroll={{ x: 'max-content' }}
				className="overflow-x-auto mt-4"
			/>
		</>
	);
};
