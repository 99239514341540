import { useCallback, useEffect, useMemo, useState } from 'react';
import { CaretLeftOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useBatchId } from '../../../../hooks/useBatchId';
import { EXAM_MODULE_KANHA_ELIGIBLE_CANDIDATES } from '../../../../shared/Routes';
import { CKRichTextEditor } from '../../../../components/rich-text-editor/CKRichTextEditor';
import moment from 'moment';
import { useSendKanhaEmails } from './useSendKanhaEmails';
import { useToastMessage } from '../../../../hooks/useToastMessage';
import {
	StringToStringMap,
	TemplateKey,
} from '../../../../shared/ExamModuleTypes';
import { DATE_DO_MMM_YYYY } from '../../../../shared/Constants';
import {
	KanhaBatchTemplate,
	KanhaBatchTemplateFilter,
} from '../../../../shared/KanhaInviteTypes';
import { preprocessContent, restoreContent } from '../../../../shared/Utils';

interface SendKanhaEmailsProps {
	templateKey: TemplateKey;
	showFilters?: boolean;
}

export const SendKanhaEmails = ({ templateKey }: SendKanhaEmailsProps) => {
	const { t } = useTranslation();
	const { navigateWithBatchId } = useBatchId();
	const { showToastMessage } = useToastMessage();
	const {
		kanhaBatchById,
		fetchKanhaBatchById,
		emailTemplate,
		onTemplateFilterChange,
		sendKanhaEmail,
		updateKanhaTemplate,
	} = useSendKanhaEmails();
	const [isEditing, setIsEditing] = useState(false);
	const [subject, setSubject] = useState(emailTemplate?.subject || '');

	const placeholderMap: StringToStringMap = useMemo(
		() => ({
			'{{CANDIDATE_NAME}}': '<span th:text="${candidateName}"></span>',
		}),
		[]
	);

	const [editorContent, setEditorContent] = useState(
		preprocessContent(emailTemplate?.htmlTemplate, placeholderMap) || ''
	);

	const isKanhaInviteEmail =
		templateKey === 'EXAM_MODULE_KANHA_BATCH_INVITE_EMAIL_TEMPLATE';

	const disableInviteEmail =
		isKanhaInviteEmail &&
		(kanhaBatchById?.status === 'INACTIVE' ||
			moment().isSameOrAfter(kanhaBatchById?.startDate));
	const disableSendKanhaEmails = disableInviteEmail || false;

	useEffect(() => {
		if (emailTemplate?.subject) {
			setSubject(emailTemplate.subject);
		}
		if (emailTemplate?.htmlTemplate) {
			setEditorContent(
				preprocessContent(emailTemplate.htmlTemplate, placeholderMap) || ''
			);
		}
	}, [emailTemplate, placeholderMap]);

	useEffect(() => {
		if (!kanhaBatchById) {
			return;
		}
		const templateFilter: KanhaBatchTemplateFilter = {
			templateKeys: [templateKey],
			modules: ['EXAM_MODULE'],
			kanhaBatchId: kanhaBatchById.id,
		};
		onTemplateFilterChange(templateFilter);
	}, [kanhaBatchById, templateKey, onTemplateFilterChange]);

	const handleUpdateTemplateClick = useCallback(() => {
		setIsEditing(true);
	}, []);

	const handleSubmitTemplateClick = useCallback(() => {
		if (!subject || !editorContent) {
			showToastMessage('error', t('subjectAndContentAreMandatory'));
			return;
		}
		if (!emailTemplate || !kanhaBatchById) {
			return;
		}
		const examTemplate: KanhaBatchTemplate = {
			id: emailTemplate.id,
			kanhaBatch: kanhaBatchById,
			htmlTemplate: restoreContent(editorContent, placeholderMap),
			module: 'EXAM_MODULE',
			templateKey,
			subject,
			sender: emailTemplate.sender,
		};
		updateKanhaTemplate(examTemplate)
			.then((isTemplateUpdated) => {
				if (isTemplateUpdated) {
					let emailType = '';
					if (isKanhaInviteEmail) {
						emailType = t('sendInviteEmail');
					} else {
						emailType = t('sendResultsEmail');
					}
					setIsEditing(false);
					Modal.info({
						title: t('updatedTemplate'),
						content: t('postUpdateTemplateMessage', { emailType }),
						okText: t('ok'),
						okButtonProps: {
							className: 'bg-nav-blue hover:!bg-hover-nav-blue',
						},
					});
				}
			})
			.catch((error: Error) => {
				showToastMessage('error', error.message);
			});
	}, [
		editorContent,
		emailTemplate,
		kanhaBatchById,
		isKanhaInviteEmail,
		showToastMessage,
		subject,
		t,
		templateKey,
		updateKanhaTemplate,
		placeholderMap,
	]);

	const postEmail = useCallback(async () => {
		await sendKanhaEmail();
	}, [sendKanhaEmail]);

	const handleSendKanhaEmailsClick = useCallback(() => {
		if (!subject || !editorContent) {
			showToastMessage('error', t('subjectAndContentAreMandatory'));
			return;
		}
		if (fetchKanhaBatchById) {
			postEmail()
				.then(() => {
					void fetchKanhaBatchById();
				})
				.catch(() => {});
		}
	}, [
		editorContent,
		fetchKanhaBatchById,
		postEmail,
		showToastMessage,
		subject,
		t,
	]);

	const Heading = () => {
		if (isKanhaInviteEmail) {
			return (
				<h2>
					{t('sendInviteEmail')} {t('toCandidates')}
				</h2>
			);
		}
		return (
			<h2>
				{t('sendResultsEmail')} {t('toCandidates')}
			</h2>
		);
	};

	return (
		<>
			<Heading />
			<div className="flex justify-between">
				<div className="flex flex-col gap-0 mb-4">
					<h3 className="mb-0">
						{t('kanhaBatch')}: {kanhaBatchById?.name}
					</h3>
					<div className="flex gap-6 mb-0">
						<h3>
							{t('startDate')}:{' '}
							{kanhaBatchById?.startDate &&
								moment(kanhaBatchById?.startDate).format(DATE_DO_MMM_YYYY)}
						</h3>
						<h3>
							{t('endDate')}:{' '}
							{kanhaBatchById?.endDate &&
								moment(kanhaBatchById?.endDate).format(DATE_DO_MMM_YYYY)}
						</h3>
					</div>
				</div>
			</div>
			<Divider className="mt-0.5 h-0.5 bg-nav-blue" />
			<div className="flex flex-col gap-5">
				{emailTemplate && (
					<>
						<div className="w-[795px] mt-0 flex justify-between">
							<Button onClick={handleUpdateTemplateClick} disabled={isEditing}>
								{t('updateTemplate')}
							</Button>
							<Button
								className="bg-nav-blue hover:!bg-hover-nav-blue"
								type="primary"
								onClick={handleSubmitTemplateClick}
								disabled={!isEditing}
							>
								{t('submitTemplate')}
							</Button>
						</div>
						<Input
							className="w-[795px] rounded-none"
							size="large"
							placeholder="Subject"
							value={subject}
							disabled={!isEditing}
							onChange={(e) => setSubject(e.target.value)}
						/>
						<CKRichTextEditor
							content={editorContent}
							disabled={!isEditing}
							onChange={(data: string) => setEditorContent(data)}
						/>
					</>
				)}
				<div className="flex gap-5">
					<Button
						className="mb-5"
						icon={<CaretLeftOutlined />}
						onClick={() => {
							navigateWithBatchId(EXAM_MODULE_KANHA_ELIGIBLE_CANDIDATES);
						}}
					>
						{t('back')}
					</Button>
					<Button
						className="mb-5 bg-nav-blue hover:!bg-hover-nav-blue"
						type="primary"
						icon={<SendOutlined />}
						onClick={handleSendKanhaEmailsClick}
						disabled={disableSendKanhaEmails || isEditing}
					>
						{t('sendEmail')}
					</Button>
				</div>
			</div>
		</>
	);
};
