import { useTranslation } from 'react-i18next';
import { KanhaBatchDetailsForm } from './KanhaBatchDetailsForm';
import { useAddorEditKanhaBatch } from './useAddorEditKanhaBatch';
import { Button } from 'antd';

export const AddorEditKanhaBatch = () => {
	const { t } = useTranslation();
	const {
		form,
		kanhaFormData,
		onBackClick,
		handleReset,
		onSubmitClick,
		submitButtonDisabled,
		kanhaBatchById,
	} = useAddorEditKanhaBatch();

	const viewButtonDisable =
		kanhaBatchById?.status === 'INACTIVE' ? true : false;
	return (
		<div className="flex flex-col px-[8px]">
			<KanhaBatchDetailsForm form={form} kanhaforminterface={kanhaFormData} />
			<div className="flex gap-4 mt-10">
				<Button className="w-36 h-12 mb-5 text-base" onClick={onBackClick}>
					{t('back')}
				</Button>
				<Button
					className="w-36 h-12 mb-5 text-base"
					danger
					disabled={viewButtonDisable}
					onClick={handleReset}
				>
					{t('clear')}
				</Button>
				<Button
					onClick={onSubmitClick}
					type="primary"
					disabled={submitButtonDisabled || viewButtonDisable}
					className={`w-36 h-12 mb-5 bg-nav-blue text-base ${
						submitButtonDisabled ? '' : 'hover:!bg-hover-nav-blue'
					}`}
				>
					{t('submit')}
				</Button>
			</div>
		</div>
	);
};
