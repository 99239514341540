import { Form, Modal } from 'antd';
import {
	CreateKanhaBatchRequest,
	KanhaBatch,
} from '../../../../../shared/KanhaInviteTypes';
import { EXAM_MODULE_KANHA_INVITE } from '../../../../../shared/Routes';
import { useBatchId } from '../../../../../hooks/useBatchId';
import { useConfirmationBeforeAction } from '../../../../../hooks/useConfirmationBeforeAction';
import { useAuth } from '../../../../../hooks/useAuth';
import { useCallback, useEffect, useState } from 'react';
import { convertDateToUTCString } from '../../../../../shared/Utils';
import {
	createKanhaBatch,
	getKanhaBatchById,
	updateKanhaBatch,
} from '../../../../../service/KanhaInviteService';
import { useCallApi } from '../../../../../hooks/useCallApi';
import { useExamModuleIds } from '../../../../../hooks/useExamModuleIds';
import { useToastMessage } from '../../../../../hooks/useToastMessage';
import { useTranslation } from 'react-i18next';
import moment, { utc } from 'moment';
import dayjs, { extend } from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';
extend(dayjsUTC);

export const useAddorEditKanhaBatch = () => {
	const { navigateWithBatchId } = useBatchId();
	const { confirmBeforeAction } = useConfirmationBeforeAction();
	const { srcmId } = useAuth();
	const { callApi } = useCallApi();
	const { kanhaBatchId, navigateWithoutExamModuleIds } = useExamModuleIds();
	const { t } = useTranslation();
	const [form] = Form.useForm<KanhaBatch>();
	const values = Form.useWatch<KanhaBatch>([], form);
	const [submitButtonDisabled, setSubmitButtonDisabled] =
		useState<boolean>(false);
	const { showToastMessage } = useToastMessage();
	const [kanhaFormData, setkanhaFormData] = useState<KanhaBatch | undefined>();
	const [kanhaBatchById, setKanhaBatchById] = useState<KanhaBatch>();
	const onBackClick = useCallback(() => {
		navigateWithoutExamModuleIds(EXAM_MODULE_KANHA_INVITE);
	}, [navigateWithoutExamModuleIds]);

	const handleReset = useCallback(() => {
		form.resetFields(); // Apply the reset values
	}, [form]);

	const generateKanhaForCreation = useCallback(
		(batchData: KanhaBatch) => {
			const kanhaBatchForCreation: KanhaBatch = {
				name: batchData.name,
				startDate: moment(convertDateToUTCString(batchData.startDate)),
				endDate: moment(convertDateToUTCString(batchData.endDate, true)),
				description: batchData.description,
			};
			if (kanhaBatchId) {
				kanhaBatchForCreation.id = kanhaBatchId;
			}
			return kanhaBatchForCreation;
		},
		[kanhaBatchId]
	);

	const onConfirmSubmit = useCallback(async () => {
		if (kanhaFormData && srcmId) {
			const savekanhaFormData = generateKanhaForCreation(kanhaFormData);
			const request: CreateKanhaBatchRequest = {
				kanhaBatch: savekanhaFormData,
				functionarySrcmId: srcmId,
			};
			try {
				if (kanhaBatchId > 0) {
					await callApi(
						() => updateKanhaBatch(request),
						'errorOccurredWhileSubmittingApplication'
					);
				} else {
					await callApi(
						() => createKanhaBatch(request),
						'errorOccurredWhileSubmittingApplication'
					);
				}
				const successContent =
					kanhaBatchId > 0
						? t('batchUpdatedSuccessfully')
						: t('batchCreatedSuccessfully');
				Modal.success({
					content: successContent,
					afterClose: () => navigateWithBatchId(EXAM_MODULE_KANHA_INVITE),
				});
			} catch (e) {
				// handled in callApi
			}
		}
	}, [
		kanhaFormData,
		srcmId,
		generateKanhaForCreation,
		kanhaBatchId,
		callApi,
		navigateWithBatchId,
		t,
	]);

	const onSubmitClick = useCallback(() => {
		const confirmContent =
			kanhaBatchId > 0
				? 'youAreAboutToUpdateKanhaBatch'
				: 'youAreAboutToAddKanhaBatch';
		confirmBeforeAction('confirmSubmit', confirmContent, onConfirmSubmit);
	}, [confirmBeforeAction, onConfirmSubmit, kanhaBatchId]);

	const handleFormValuesChange = useCallback(async () => {
		try {
			const fieldValues = await form.validateFields({ validateOnly: true });
			setSubmitButtonDisabled(false);
			setkanhaFormData(fieldValues);
		} catch (e: unknown) {
			setSubmitButtonDisabled(true);
		}
	}, [form]);

	useEffect(() => {
		void handleFormValuesChange();
	}, [handleFormValuesChange, values]);

	// Convert Moment to Day.js
	const convertMomentToDayjs = useCallback(
		(date: moment.Moment | undefined) => {
			return date ? dayjs(date.toString()) : undefined; // Convert moment to dayjs
		},
		[]
	);

	const fetchKanhaBatchById = useCallback(async () => {
		if (srcmId && kanhaBatchId) {
			try {
				const response = await callApi(
					() =>
						getKanhaBatchById({
							srcmId,
							kanhaBatchId,
						}),
					'errorOccurredWhileFetchingExam'
				);

				const updatedResponse = {
					code: response.code,
					endDate: utc(response.endDate),
					name: response.name,
					id: kanhaBatchId,
					startDate: utc(response.startDate),
					status: response.status,
					description: response.description,
				};
				setkanhaFormData(updatedResponse);

				//Returning back to frontEnd to set the field Values
				form.setFieldsValue({
					name: updatedResponse.name,
					description: updatedResponse.description,
					startDate: convertMomentToDayjs(updatedResponse?.startDate),
					endDate: convertMomentToDayjs(updatedResponse?.endDate)?.subtract(
						1,
						'day'
					),
				});

				if (updatedResponse.status === 'INACTIVE') {
					setSubmitButtonDisabled(true);
				} else {
					setSubmitButtonDisabled(false);
				}

				setKanhaBatchById(updatedResponse);
			} catch (e) {
				if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', 'Unknown error occurred');
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [kanhaBatchId, srcmId, form, showToastMessage]);

	useEffect(() => {
		void fetchKanhaBatchById();
	}, [fetchKanhaBatchById]);

	return {
		form,
		onBackClick,
		handleReset,
		onSubmitClick,
		submitButtonDisabled,
		kanhaFormData,
		fetchKanhaBatchById,
		kanhaBatchById,
	};
};
