import { useTranslation } from 'react-i18next';
import { CandidateExaminationDetail } from '../../shared/ExamModuleTypes';
import { List } from 'antd';

interface ExamFailedCardProps {
	candidateExamDetail: CandidateExaminationDetail;
}

export const ExamFailedCard = ({
	candidateExamDetail,
}: ExamFailedCardProps) => {
	const { t } = useTranslation();
	const candidateName = `${candidateExamDetail.profile.name}`;
	const overallPercentage = candidateExamDetail.totalPercentage || 0;
	const passPercentage = candidateExamDetail.exam.passPercentage;

	const scores = [
		t('overallScore', { overallPercentage }),
		...candidateExamDetail.sectionCategories.map((section) =>
			t(`${section.sectionName.replace(/\s/g, '')}Score`, {
				sectionMarksObtained: section.totalMarksObtained || 0,
				sectionTotalMarks: section.totalMarksLimit,
			})
		),
	];

	return (
		<div className="p-4">
			<p>{t('dearCandidateName', { candidateName })}</p>
			<p className="text-justify">{t('examFailedPara1', { passPercentage })}</p>
			<p className="text-justify">{t('examFailedPara2')}</p>
			<List
				size="small"
				bordered
				dataSource={scores}
				renderItem={(item, index) => (
					<List.Item className="border-0 p-0 flex items-center">
						<span className="mr-2">{index + 1}.</span> {item}
					</List.Item>
				)}
				className="list-none p-0"
			/>
			<p className="text-justify">{t('examFailedPara3')}</p>
			<p className="text-justify">{t('examFailedPara4')}</p>
			<p className="text-justify">{t('examFailedPara5')}</p>
			<p className="text-justify">{t('examFailedPara6')}</p>
			<p className="text-justify">{t('pcmsTeam')}</p>
		</div>
	);
};
