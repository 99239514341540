import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { useKanhaEligibleCandidates } from './useKanhaEligibleCandidates';
import { useExamModuleIds } from '../../../../hooks/useExamModuleIds';
import { tablePaginationConfiguration } from '../../../../shared/Utils';
import { KanhaEligibleCandidateTableColumns } from './KanhaEligibleCandidateTableColumns';
import { Button, Table } from 'antd';
import {
	ArrowLeftOutlined,
	MailOutlined,
	UploadOutlined,
} from '@ant-design/icons';
import { CandidatesUploadModal } from '../../../../components/exam-module/CandidatesUploadModal';
import {
	EXAM_MODULE_KANHA_INVITE,
	SEND_KANHA_INVITE_EMAIL,
} from '../../../../shared/Routes';
import { ResultsUploadModal } from '../../../../components/exam-module/ResultsUploadModal';
import { useToastMessage } from '../../../../hooks/useToastMessage';
import { FilterKanhaEligibleCandidates } from './FilterKanhaEligibleCandidates';
import moment from 'moment';
import { DATE_DO_MMM_YYYY } from '../../../../shared/Constants';
import { useBatchId } from '../../../../hooks/useBatchId';

export const KanhaEligibleCandidates = () => {
	const {
		kanhaBatchById,
		kanhaBatchFilter,
		kanhaBatchCandidatesList,
		kanhaBatchCandidatePaging,
		kanhaCandidateCenters,
		kanhaCandidateZones,
		fetchKanhaBatchById,
		onKanhaBatchCandidatesPageChange,
		onKanhaBatchCandidatesFilterChange,
		uploadKanhaEligibleCandidates,
		downloadKanhaResultsTemplate,
		uploadKanhaResults,
		exportKanhaEligibleCandidates,
	} = useKanhaEligibleCandidates();
	const { kanhaBatchCandidateDetails, pagination } = kanhaBatchCandidatesList;
	const { showToastMessage } = useToastMessage();
	const { t } = useTranslation();
	const { navigateWithBatchId } = useBatchId();
	const { navigateWithoutExamModuleIds } = useExamModuleIds();
	const [showCandidatesUploadModal, setShowCandidatesUploadModal] =
		useState(false);
	const [showResultsUploadModal, setShowResultsUploadModal] = useState(false);
	const [downloadLoading, setDownloadLoading] = useState(false);
	const tablePaginationConfig = tablePaginationConfiguration(
		kanhaBatchCandidatePaging,
		pagination,
		onKanhaBatchCandidatesPageChange
	);

	const columns = KanhaEligibleCandidateTableColumns();

	const closeCandidatesUploadModal = useCallback(() => {
		setShowCandidatesUploadModal(false);
	}, []);

	const handleResultsModalClose = useCallback(() => {
		fetchKanhaBatchById().catch((error: Error) => {
			showToastMessage('error', error.message);
		});
		onKanhaBatchCandidatesFilterChange(kanhaBatchFilter);
	}, [
		kanhaBatchFilter,
		fetchKanhaBatchById,
		onKanhaBatchCandidatesFilterChange,
		showToastMessage,
	]);

	const closeResultsUploadModal = useCallback(() => {
		handleResultsModalClose();
		setShowResultsUploadModal(false);
	}, [handleResultsModalClose]);

	const isCandidatesListEmpty =
		kanhaBatchCandidateDetails === undefined ||
		(kanhaBatchCandidateDetails.length &&
			kanhaBatchCandidateDetails.length === 0)
			? true
			: false;
	const disableSendInvite =
		isCandidatesListEmpty ||
		kanhaBatchById?.status === 'INACTIVE' ||
		moment().isSameOrAfter(kanhaBatchById?.startDate);
	const disableUploadCandidates =
		kanhaBatchById?.status === 'INACTIVE' ||
		moment().isSameOrAfter(kanhaBatchById?.startDate);
	const disableResultsUpload =
		isCandidatesListEmpty ||
		kanhaBatchById?.status === 'INACTIVE' ||
		moment().isBefore(kanhaBatchById?.endDate) ||
		kanhaBatchById?.resultEmailSent;
	const disableExportButton = isCandidatesListEmpty;

	const onExportClick = useCallback(async () => {
		await exportKanhaEligibleCandidates();
	}, [exportKanhaEligibleCandidates]);

	const handleExportClick = useCallback(() => {
		setDownloadLoading(true);
		onExportClick()
			.catch((error: Error) => {
				showToastMessage('error', error.message);
			})
			.finally(() => {
				setDownloadLoading(false);
			});
	}, [onExportClick, showToastMessage]);

	const DisplayCandidates = () => {
		return (
			<>
				{kanhaBatchById && (
					<FilterKanhaEligibleCandidates
						value={kanhaBatchFilter}
						onFilterChange={onKanhaBatchCandidatesFilterChange}
						kanhaBatchById={kanhaBatchById}
						kanhaCandidateCenters={kanhaCandidateCenters}
						kanhaCandidateZones={kanhaCandidateZones}
						disableFilters={isCandidatesListEmpty}
					/>
				)}
				<div className="flex gap-4 mt-4">
					<Button
						className="mb-5"
						icon={<UploadOutlined />}
						size="middle"
						onClick={() => {
							setShowCandidatesUploadModal(true);
						}}
						disabled={disableUploadCandidates}
					>
						{t('uploadEligibleCandidates')}
					</Button>
					<Button
						className="mb-5"
						icon={<MailOutlined />}
						size="middle"
						onClick={() => {
							navigateWithBatchId(SEND_KANHA_INVITE_EMAIL);
						}}
						disabled={disableSendInvite}
					>
						{t('sendInviteEmail')}
					</Button>
					<Button
						className="mb-5"
						icon={<UploadOutlined />}
						onClick={handleExportClick}
						loading={downloadLoading}
						disabled={disableExportButton}
					>
						{t('exportCandidates')}
					</Button>
					<Button
						className="mb-5"
						icon={<UploadOutlined />}
						size="middle"
						onClick={() => {
							setShowResultsUploadModal(true);
						}}
						disabled={disableResultsUpload}
					>
						{t('statusUpload')}
					</Button>
				</div>
				<Table
					dataSource={kanhaBatchCandidateDetails}
					columns={columns}
					pagination={tablePaginationConfig}
					scroll={{ x: 'max-content' }}
					className="overflow-x-auto"
				/>
				<CandidatesUploadModal
					openModal={showCandidatesUploadModal}
					onCancel={closeCandidatesUploadModal}
					uploadEligibleCandidates={uploadKanhaEligibleCandidates}
				/>
				<ResultsUploadModal
					openModal={showResultsUploadModal}
					onCancel={closeResultsUploadModal}
					downloadResultsTemplate={downloadKanhaResultsTemplate}
					uploadResults={uploadKanhaResults}
				/>
			</>
		);
	};

	return (
		<>
			<>
				<div className="flex justify-between items-center">
					<div className="flex flex-col gap-0 mb-4">
						<h3 className="mb-0">
							{t('kanhaBatch')}: {kanhaBatchById?.name}
						</h3>
						<div className="flex gap-6 mb-0">
							<h3>
								{t('startDate')}:{' '}
								{kanhaBatchById?.startDate &&
									moment(kanhaBatchById?.startDate).format(DATE_DO_MMM_YYYY)}
							</h3>
							<h3>
								{t('endDate')}:{' '}
								{kanhaBatchById?.endDate &&
									moment(kanhaBatchById?.endDate).format(DATE_DO_MMM_YYYY)}
							</h3>
						</div>
					</div>

					<Button
						className="bg-nav-blue hover:!bg-hover-nav-blue text-white !rounded-none"
						type="primary"
						size="large"
						onClick={() =>
							navigateWithoutExamModuleIds(EXAM_MODULE_KANHA_INVITE)
						}
						icon={<ArrowLeftOutlined />}
					>
						{t('back')}
					</Button>
				</div>
				<DisplayCandidates />
			</>
		</>
	);
};
