import { TableColumnsType } from 'antd';
import { KanhaBatchCandidateDetail } from '../../../../shared/KanhaInviteTypes';
import { useTranslation } from 'react-i18next';

export const KanhaEligibleCandidateTableColumns =
	(): TableColumnsType<KanhaBatchCandidateDetail> => {
		const { t } = useTranslation();

		return [
			{
				title: t('abhyasiId'),
				dataIndex: 'srcmId',
				key: 'abhyasiId',
				render: (__, record, index) => (
					<span key={index}>{record?.profile.srcmId}</span>
				),
			},
			{
				title: t('name'),
				dataIndex: 'profile.name',
				key: 'name',
				render: (__, record, index) => (
					<span key={index}>{record?.profile.name}</span>
				),
			},
			{
				title: t('candidateCenter'),
				dataIndex: 'profile.center',
				key: 'candidateCenter',
				render: (__, record, index) => (
					<span key={index}>{record?.profile.center}</span>
				),
			},
			{
				title: t('candidateZone'),
				dataIndex: 'profile.zone',
				key: 'candidateZone',
				render: (__, record, index) => (
					<span key={index}>{record?.profile.zone}</span>
				),
			},
			{
				title: t('trainingAttendance'),
				dataIndex: 'attendanceStatus',
				key: 'attendanceStatus',
				render: (__, record, index) => (
					<span key={index}>
						{record?.attendanceStatus
							? t(record.attendanceStatus.toLowerCase())
							: '-'}
					</span>
				),
			},
			{
				title: t('completionStatus'),
				dataIndex: 'examResultStatus',
				key: 'examResultStatus',
				render: (__, record, index) => {
					if (record.resultUploaded && record.attendanceStatus === 'ABSENT') {
						return <span key={index}>{'NA'}</span>;
					} else if (record.resultStatus === 'CLEARED') {
						return <span key={index}>{t('completedStatus')}</span>;
					} else if (record.resultStatus === 'NOT_CLEARED') {
						return <span key={index}>{t('incompleteStatus')}</span>;
					} else {
						return <span key={index}>{'-'}</span>;
					}
				},
			},
		];
	};
