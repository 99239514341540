import { useCallback } from 'react';
import { useConfirmationBeforeAction } from '../../../hooks/useConfirmationBeforeAction';
import { useCallApi } from '../../../hooks/useCallApi';
import {
	cancelExam,
	changeExaminationCenter,
	getExaminationCenterDetailForRegistration,
	registerForExam,
} from '../../../service/ExamModuleService';
import {
	CancelExamRequest,
	CandidateExaminationDetail,
	ChangeExaminationCenterRequest,
	RegisterForExamRequest,
} from '../../../shared/ExamModuleTypes';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { useExamModule } from '../../exam-module/useExamModule';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useLoadingIndicator } from '../../../hooks/useLoadingIndicator';
import { TestCenterSelectionFormType } from '.';
import { useBatchId } from '../../../hooks/useBatchId';
import { APPLICANT_HOME } from '../../../shared/Routes';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const useExamRegistration = () => {
	const { activeExam } = useExamModule();
	const { t } = useTranslation();
	const { setLoading } = useLoadingIndicator();
	const { showToastMessage } = useToastMessage();
	const { confirmBeforeAction } = useConfirmationBeforeAction();
	const { callApi } = useCallApi();
	const { navigateWithBatchId } = useBatchId();

	const onConfirmSubmit = useCallback(
		async (
			registrationDetails: TestCenterSelectionFormType,
			changeExamCenter?: boolean
		) => {
			if (registrationDetails) {
				try {
					const accommodation =
						registrationDetails.accommodation === t('yes').toUpperCase();
					const request: RegisterForExamRequest = {
						srcmId: registrationDetails.srcmId,
						examId: activeExam?.id,
						examinationCenterId: parseInt(registrationDetails.center),
						preferredLanguageForExamination: registrationDetails.language,
						accommodationRequired: accommodation,
						noOfAccommodations: registrationDetails.accommodationNo,
						specialRequest: registrationDetails.specialRequests,
					};
					let response: CandidateExaminationDetail;
					if (
						changeExamCenter &&
						registrationDetails.candidateExaminationDetailId
					) {
						const changeRequest: ChangeExaminationCenterRequest = {
							...request,
							changeOfCenterReason: registrationDetails.changeOfCenterReason,
							candidateExaminationDetailId:
								registrationDetails.candidateExaminationDetailId,
						};
						response = await callApi(
							() => changeExaminationCenter(changeRequest),
							'errorOccurredWhileChanging'
						);
					} else {
						response = await callApi(
							() => registerForExam(request),
							'errorOccurredWhileRegistering'
						);
					}
					if (response) {
						Modal.success({
							title: changeExamCenter
								? t('updationSuccessful')
								: t('registrationSuccessful'),
							content:
								activeExam?.registrationEndMessage ||
								(changeExamCenter
									? t('updationSuccessful')
									: t('registrationSuccessful')),
							afterClose: () => {
								navigateWithBatchId(APPLICANT_HOME);
								return;
							},
							okButtonProps: {
								className: 'bg-nav-blue hover:!bg-hover-nav-blue',
							},
						});
					}
				} catch (e) {
					// handled in callApi
				}
			}
		},
		[activeExam, callApi, t, navigateWithBatchId]
	);

	const onSubmitClick = useCallback(
		(
			registrationDetails: TestCenterSelectionFormType,
			changeExamCenter?: boolean
		) => {
			const confirmationMessage = changeExamCenter
				? 'changeTestCenterConfirmationMessage'
				: 'testCenterOnceSubmittedCanNotBeChanged';
			confirmBeforeAction(
				'confirmSubmit',
				confirmationMessage,
				() => onConfirmSubmit(registrationDetails, changeExamCenter),
				true
			);
		},
		[confirmBeforeAction, onConfirmSubmit]
	);

	const onConfirmCancelExam = useCallback(
		async (cancelExamRequest: CancelExamRequest) => {
			if (cancelExamRequest) {
				try {
					const response = await callApi(
						() => cancelExam(cancelExamRequest),
						'errorOccurredWhileCancelling'
					);
					if (response) {
						Modal.success({
							title: t('examCancelledMessage'),
							content: t('examCancelledSuccessfully'),
							afterClose: () => {
								navigateWithBatchId(APPLICANT_HOME);
								return;
							},
							okButtonProps: {
								className: 'bg-nav-blue hover:!bg-hover-nav-blue',
							},
						});
					}
				} catch (e) {
					// handled in callApi
				}
			}
		},
		[callApi, t, navigateWithBatchId]
	);

	const onCancelExamClick = useCallback(
		(cancelExamRequest: CancelExamRequest) => {
			Modal.confirm({
				title: t('confirmSubmit'),
				content: t('cancelExamConfirmationMessage'),
				icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
				onOk: () => onConfirmCancelExam(cancelExamRequest),
				onCancel() {},
				cancelText: t('cancel'),
				okButtonProps: {
					className: 'bg-nav-blue hover:!bg-hover-nav-blue',
				},
			});
		},
		[onConfirmCancelExam, t]
	);

	const fetchExamCenterDetail = useCallback(
		async (examinationCenterId: number) => {
			if (activeExam?.id) {
				try {
					setLoading(true);
					const response = await getExaminationCenterDetailForRegistration({
						examId: activeExam.id,
						examinationCenterId,
					});
					return response;
				} catch (e) {
					if (e instanceof Error) {
						showToastMessage('error', e.message);
					} else {
						showToastMessage('error', 'Unknown error occurred');
					}
				} finally {
					setLoading(false);
				}
			}
		},
		[activeExam, setLoading, showToastMessage]
	);

	return {
		activeExam,
		fetchExamCenterDetail,
		onSubmitClick,
		onCancelExamClick,
	};
};
