import { useCallback } from 'react';
import { Space, TableColumnsType, Tag, Tooltip } from 'antd';
import { KanhaBatch } from '../../../../shared/KanhaInviteTypes';
import { useTranslation } from 'react-i18next';
import { CalendarOutlined, EyeOutlined } from '@ant-design/icons';
import { utc } from 'moment';
import { DATE_DD_MM_YYYY } from '../../../../shared/Constants';
import { useExamModuleIds } from '../../../../hooks/useExamModuleIds';
import {
	EDIT_KANHA_BATCH,
	EXAM_MODULE_KANHA_ELIGIBLE_CANDIDATES,
} from '../../../../shared/Routes';

export const KanhaBatchTableColumns = (): TableColumnsType<KanhaBatch> => {
	const { t } = useTranslation();
	const { navigateWithKanhaBatchId } = useExamModuleIds();

	const COLUMN_STYLE = 'w-48';

	const handleEditKanhaBatchClick = useCallback(
		(kanhaBatchId?: number) => {
			if (kanhaBatchId) {
				navigateWithKanhaBatchId(EDIT_KANHA_BATCH, kanhaBatchId);
			}
		},
		[navigateWithKanhaBatchId]
	);

	const handleViewEligibleCandidatesClick = useCallback(
		(kanhaBatchId: number) => {
			navigateWithKanhaBatchId(
				EXAM_MODULE_KANHA_ELIGIBLE_CANDIDATES,
				kanhaBatchId
			);
		},
		[navigateWithKanhaBatchId]
	);

	return [
		{
			title: t('kanhaBatchName'),
			dataIndex: 'name',
			key: 'kanhaBatchName',
			render: (__, record) => (
				<Tooltip title={record.name}>
					<div>{record.name}</div>
				</Tooltip>
			),
		},
		{
			title: t('startDate'),
			dataIndex: 'startDate',
			key: 'startDate',
			render: (__, record) => (
				<span className="flex items-center whitespace-nowrap">
					<CalendarOutlined />
					<span className="ml-2">
						{utc(record.startDate).format(DATE_DD_MM_YYYY)}
					</span>
				</span>
			),
		},
		{
			title: t('endDate'),
			dataIndex: 'endDate',
			key: 'endDate',
			render: (__, record) => (
				<span className="flex items-center whitespace-nowrap">
					<CalendarOutlined />
					<span className="ml-2">
						{utc(record.endDate).format(DATE_DD_MM_YYYY)}
					</span>
				</span>
			),
		},
		{
			title: t('description'),
			dataIndex: 'description',
			key: 'description',
			render: (__, record) => (
				<Tooltip title={record.description}>
					<div className={`truncate ${COLUMN_STYLE}`}>{record.description}</div>
				</Tooltip>
			),
		},
		{
			title: t('eligibleCandidates'),
			dataIndex: 'viewEligibleCandidates',
			key: 'operationEligibleCandidates',
			render: (__, record) => (
				<Tooltip title={t('viewEligibleCandidates')}>
					<a
						className="underline hover:underline-offset-4"
						onClick={() =>
							record.id && handleViewEligibleCandidatesClick(record.id)
						}
					>
						<EyeOutlined className="text-xl" />
					</a>
				</Tooltip>
			),
		},
		{
			title: t('status'),
			dataIndex: 'status',
			key: 'status',
			render: (__, record, index) => {
				if (record.status === 'ACTIVE') {
					return (
						<span key={index}>
							<Tag className="text-sm" color="success">
								{t('active')}
							</Tag>
						</span>
					);
				} else if (record.status === 'INACTIVE') {
					return (
						<span key={index}>
							<Tag className="text-sm" color="error">
								{t('inactive')}
							</Tag>
						</span>
					);
				}
			},
		},
		{
			title: t('action'),
			key: 'operation',
			fixed: 'right',
			width: 150,
			render: (__, record) => (
				<Space size="middle" className="whitespace-nowrap">
					<a
						className="underline hover:underline-offset-4"
						onClick={() => handleEditKanhaBatchClick(record.id)}
					>
						{record.status === 'ACTIVE' ? t('editOrUpdate') : t('view')}
					</a>
				</Space>
			),
		},
	];
};
