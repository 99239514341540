import {
	AreaChartOutlined,
	EnvironmentOutlined,
	FileImageOutlined,
	FormOutlined,
} from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import {
	CREATE_CENTER,
	CREATE_EXAM,
	CREATE_KANHA_BATCH,
	EDIT_KANHA_BATCH,
	EXAM_MODULE_CENTERS_LIST,
	EXAM_MODULE_ELIGIBLE_CANDIDATES,
	EXAM_MODULE_EXAMS_LIST,
	EXAM_MODULE_HOME,
	EXAM_MODULE_KANHA_ELIGIBLE_CANDIDATES,
	EXAM_MODULE_KANHA_INVITE,
	EXAM_MODULE_VIEW_CANDIDATES,
	EXAM_MODULE_VIEW_HELD_CANDIDATES,
	SEND_ADMIT_CARD_EMAIL,
	SEND_INVITE_EMAIL,
	SEND_RESULTS_EMAIL,
	UPDATE_CENTER,
	UPDATE_EXAM,
	VIEW_CENTER,
	VIEW_EXAM,
} from '../../shared/Routes';
import { useExamModuleIds } from '../../hooks/useExamModuleIds';

type MenuItem = Required<MenuProps>['items'][number];

const SideMenu = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const { navigateWithoutExamModuleIds } = useExamModuleIds();
	const normalizedPath = location.pathname.replace(/\/$/, '');

	const getActiveMenuKey = (path: string): string => {
		const examPaths = [
			EXAM_MODULE_EXAMS_LIST,
			CREATE_EXAM,
			UPDATE_EXAM,
			VIEW_EXAM,
			EXAM_MODULE_VIEW_CANDIDATES,
			EXAM_MODULE_VIEW_HELD_CANDIDATES,
			EXAM_MODULE_ELIGIBLE_CANDIDATES,
			SEND_INVITE_EMAIL,
			SEND_ADMIT_CARD_EMAIL,
			SEND_RESULTS_EMAIL,
		];
		const centerPaths = [
			EXAM_MODULE_CENTERS_LIST,
			CREATE_CENTER,
			UPDATE_CENTER,
			VIEW_CENTER,
		];
		const kanhaPaths = [
			EXAM_MODULE_KANHA_INVITE,
			CREATE_KANHA_BATCH,
			EDIT_KANHA_BATCH,
			EXAM_MODULE_KANHA_ELIGIBLE_CANDIDATES,
		];

		if (examPaths.some((p) => path.startsWith(p))) {
			return EXAM_MODULE_EXAMS_LIST;
		}

		if (centerPaths.some((p) => path.startsWith(p))) {
			return EXAM_MODULE_CENTERS_LIST;
		}

		if (kanhaPaths.some((p) => path.startsWith(p))) {
			return EXAM_MODULE_KANHA_INVITE;
		}

		return path;
	};

	const items: MenuItem[] = [
		{
			key: EXAM_MODULE_HOME,
			icon: <AreaChartOutlined style={{ fontSize: '20px' }} />,
			label: <span className="font-semibold text-base">{t('dashboard')}</span>,
		},
		{
			key: EXAM_MODULE_EXAMS_LIST,
			icon: <FormOutlined style={{ fontSize: '20px' }} />,
			label: <span className="font-semibold text-base">{t('exams')}</span>,
		},
		{
			key: EXAM_MODULE_CENTERS_LIST,
			icon: <EnvironmentOutlined style={{ fontSize: '20px' }} />,
			label: <span className="font-semibold text-base">{t('centers')}</span>,
		},
		{
			key: EXAM_MODULE_KANHA_INVITE,
			icon: <FileImageOutlined style={{ fontSize: '20px' }} />,
			label: (
				<span className="font-semibold text-base">{t('kanhaInvite')}</span>
			),
		},
	];

	return (
		<Menu
			onClick={({ key }) => {
				navigateWithoutExamModuleIds(key);
			}}
			items={items}
			selectedKeys={[getActiveMenuKey(normalizedPath)]}
		/>
	);
};

const MenuContent = () => {
	return (
		<div className="mx-8 my-2 flex-1 overflow-auto">
			<Outlet />
		</div>
	);
};

export const ExamModuleHome = () => {
	return (
		<div className="flex flex-row min-h-[90vh]">
			<div className="w-48">
				<SideMenu />
			</div>
			<MenuContent />
		</div>
	);
};
