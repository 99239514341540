import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { useCallApi } from '../../../hooks/useCallApi';
import {
	Exam,
	ExamFilter,
	GetExamsResponse,
} from '../../../shared/ExamModuleTypes';
import { PagingConfig } from '../../../shared/VolunteerApplicationServiceTypes';
import { DEFAULT_PAGE_SIZE } from '../../../shared/Constants';
import { getExamById, getExams } from '../../../service/ExamModuleService';
import { useExamModuleIds } from '../../../hooks/useExamModuleIds';

interface UseExamsListConfig {
	fetchAllExams?: boolean;
}

export const useExamsList = ({ fetchAllExams = false }: UseExamsListConfig) => {
	const { srcmId } = useAuth();
	const { callApi } = useCallApi();
	const { examId } = useExamModuleIds();
	const [examsList, setExamsList] = useState<GetExamsResponse>({
		exams: [],
		pagination: { totalNoOfRecords: 0 },
	});
	const [examFilter, setExamFilter] = useState<ExamFilter>({});
	const [examsPaging, setExamsPaging] = useState<PagingConfig>({
		pageNo: 1,
		pageSize: DEFAULT_PAGE_SIZE,
	});
	const [examById, setExamById] = useState<Exam>();

	const onExamsPageChange = useCallback(
		(pageNumber: number, pageSize: number) => {
			setExamsPaging({ pageNo: pageNumber, pageSize });
		},
		[]
	);

	const onExamsFilterChange = useCallback(
		(filterParam: ExamFilter) => {
			setExamFilter(filterParam);
			onExamsPageChange(1, examsPaging.pageSize ?? DEFAULT_PAGE_SIZE);
		},
		[onExamsPageChange, examsPaging.pageSize]
	);

	const fetchExamsList = useCallback(
		async (pageNumber: number, pageSize: number, filter: ExamFilter) => {
			if (srcmId) {
				const pageIndex = pageNumber < 1 ? 0 : pageNumber - 1;
				const examsWithoutKey = await callApi(
					() =>
						getExams({
							functionarySrcmId: srcmId,
							pageIndex,
							pageSize,
							examFilter: filter,
						}),
					'errorOccurredWhileLoading'
				);
				const CED = {
					...examsWithoutKey,
					...(examsWithoutKey.exams && {
						exams: examsWithoutKey.exams.map((exam) => ({
							...exam,
							key: exam.id,
						})),
					}),
				};

				if (
					CED.pagination.totalNoOfPages &&
					CED.pagination.totalNoOfRecords > 0 &&
					pageNumber > CED.pagination.totalNoOfPages
				) {
					onExamsPageChange(CED.pagination.totalNoOfPages ?? 1, pageSize);
				} else {
					setExamsList(CED);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onExamsPageChange, srcmId]
	);

	useEffect(() => {
		void fetchExamsList(
			examsPaging.pageNo ?? 1,
			fetchAllExams ? 2000 : examsPaging.pageSize ?? DEFAULT_PAGE_SIZE,
			examFilter
		);
	}, [examsPaging, examFilter, fetchExamsList, fetchAllExams]);

	const fetchExamById = useCallback(async () => {
		if (srcmId && examId) {
			const response = await callApi(
				() =>
					getExamById({
						srcmId,
						examId,
					}),
				'errorOccurredWhileFetchingExam'
			);
			setExamById(response);
			return response;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [examId, srcmId]);

	useEffect(() => {
		void fetchExamById();
	}, [fetchExamById]);

	return {
		examById,
		examsList,
		examsPaging,
		examFilter,
		onExamsPageChange,
		onExamsFilterChange,
		fetchExamById,
	};
};
