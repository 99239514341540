import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { useToastMessage } from '../../../../hooks/useToastMessage';
import { SendEmailRequest } from '../../../../shared/ExamModuleTypes';
import { useCallApi } from '../../../../hooks/useCallApi';
import {
	KanhaBatchFilter,
	KanhaBatchTemplate,
	KanhaBatchTemplateFilter,
	UpdateKanhaBatchTemplateRequest,
} from '../../../../shared/KanhaInviteTypes';
import { useAddorEditKanhaBatch } from '../batches-list/AddorEditKanhaBatch/useAddorEditKanhaBatch';
import {
	getKanhaBatchTemplates,
	sendKanhaBatchInviteEmail,
	updateKanhaBatchTemplate,
} from '../../../../service/KanhaInviteService';

export const useSendKanhaEmails = () => {
	const [emailTemplate, setEmailTemplate] = useState<KanhaBatchTemplate>();
	const { srcmId } = useAuth();
	const { showToastMessage } = useToastMessage();
	const { callApi } = useCallApi();
	const { kanhaBatchById, fetchKanhaBatchById } = useAddorEditKanhaBatch();
	const [kanhaBatchTemplateFilter, setKanhaBatchTemplateFilter] =
		useState<KanhaBatchTemplateFilter>();
	const [kanhaBatchFilter, setKanhaBatchFilter] = useState<KanhaBatchFilter>();

	useEffect(() => {
		if (kanhaBatchById && kanhaBatchById.id) {
			setKanhaBatchFilter((prevState) => ({
				...prevState,
				kanhaBatchId: kanhaBatchById?.id,
			}));
		}
	}, [kanhaBatchById]);

	const onTemplateFilterChange = useCallback(
		(filterParam: KanhaBatchTemplateFilter) => {
			setKanhaBatchTemplateFilter(filterParam);
		},
		[]
	);

	const fetchKanhaBatchTemplate = useCallback(
		async (filter: KanhaBatchTemplateFilter) => {
			if (!srcmId) {
				return;
			}
			const templatesResponse = await callApi(
				() =>
					getKanhaBatchTemplates({
						functionarySrcmId: srcmId,
						pageIndex: 0,
						pageSize: 2000,
						templateFilter: filter,
					}),
				'errorOccurredWhileLoading'
			);
			if (
				templatesResponse.kanhaBatchTemplates &&
				templatesResponse.kanhaBatchTemplates.length
			) {
				setEmailTemplate(templatesResponse.kanhaBatchTemplates[0]);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[srcmId]
	);

	useEffect(() => {
		if (kanhaBatchTemplateFilter) {
			void fetchKanhaBatchTemplate(kanhaBatchTemplateFilter);
		}
	}, [kanhaBatchTemplateFilter, fetchKanhaBatchTemplate]);

	const updateKanhaTemplate = useCallback(
		async (kanhaBatchTemplate: KanhaBatchTemplate) => {
			if (!srcmId) {
				return;
			}
			const request: UpdateKanhaBatchTemplateRequest = {
				functionarySrcmId: srcmId,
				kanhaBatchTemplate,
			};
			const response = await callApi(
				() => updateKanhaBatchTemplate(request),
				'errorOccurredWhileLoading'
			);
			if (response) {
				return true;
			} else {
				return false;
			}
		},
		[callApi, srcmId]
	);

	const sendKanhaEmail = useCallback(async () => {
		if (!srcmId) {
			return;
		}
		if (kanhaBatchById?.id) {
			const request: SendEmailRequest = {
				functionarySrcmId: srcmId,
				kanhaBatchFilter,
			};
			const response = await callApi(
				() => sendKanhaBatchInviteEmail(request),
				'errorOccurredWhileSendingEmail'
			);
			if (response) {
				showToastMessage('success', 'Email sent successfully');
				if (fetchKanhaBatchById) {
					void fetchKanhaBatchById();
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		kanhaBatchById,
		kanhaBatchFilter,
		kanhaBatchTemplateFilter,
		showToastMessage,
		srcmId,
	]);

	return {
		kanhaBatchById,
		emailTemplate,
		fetchKanhaBatchById,
		onTemplateFilterChange,
		sendKanhaEmail,
		updateKanhaTemplate,
	};
};
