import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Select, Typography } from 'antd';
import {
	VALID_ABHYASI_ID_REGEX,
	VALID_NAME_REGEX,
} from '../../../shared/Constants';
import {
	Exam,
	ExamFilter,
	ExaminationCenterMapping,
	ExaminationLanguageMapping,
} from '../../../shared/ExamModuleTypes';

interface FilterProps {
	value: ExamFilter;
	onFilterChange: (value: ExamFilter) => void;
	examById: Exam;
	disableFilters?: boolean;
}

const { Text } = Typography;

interface CandidatesFilterFormType {
	selectedExamCenters?: number;
	languages?: string;
	srcmId?: string;
	name?: string;
}

export const FilterCandidates = ({
	value,
	onFilterChange,
	examById,
	disableFilters = false,
}: FilterProps) => {
	const { t } = useTranslation();
	const [form] = Form.useForm<CandidatesFilterFormType>();
	const values = Form.useWatch<CandidatesFilterFormType>([], form);

	const handleFilterClick = useCallback(() => {
		const filterValues: ExamFilter = {
			...values,
			srcmId: values?.srcmId,
			name: values?.name,
			preferredExaminationLanguages: [values?.languages] as Array<string>,
			examCenterIds: [values?.selectedExamCenters] as Array<number>,
		};
		const examFilters = { ...value, ...filterValues };
		onFilterChange(examFilters);
	}, [onFilterChange, value, values]);

	const handleResetForm = useCallback(() => {
		form.resetFields();
		const filterValues: ExamFilter = {
			examId: examById.id,
			registrationStatusArray: ['REGISTERED'],
		};
		onFilterChange(filterValues);
	}, [examById, form, onFilterChange]);

	const examCenterOptions = examById.examinationCenterMappings.map(
		(item: ExaminationCenterMapping) => ({
			value: item.examinationCenter.id,
			label: item.examinationCenter.centerName,
		})
	);

	const examLanguageOptions = examById.examinationLanguageMappings.map(
		(item: ExaminationLanguageMapping) => ({
			value: item.examinationLanguage.language,
			label: item.examinationLanguage.language,
		})
	);

	return (
		<div className="flex flex-col">
			<Form
				name="candidatesFilterForm"
				layout="horizontal"
				size="middle"
				labelCol={{ span: 24 }}
				wrapperCol={{ span: 24 }}
				autoComplete="off"
				initialValues={value}
				form={form}
				disabled={disableFilters}
			>
				<div className="flex flex-col md:mb-0">
					<div className="flex flex-col gap-4 md:flex-row">
						<div className="flex flex-col md:mb-0 w-full min-w-[170px]">
							<Text className="pl-1">{t('examCenter')}</Text>
							<Form.Item className="!mb-2" name={'selectedExamCenters'}>
								<Select
									showSearch
									options={examCenterOptions}
									filterOption={(input, option) =>
										(option?.label ?? '')
											.toLowerCase()
											.includes(input.toLowerCase())
									}
									placeholder={t('searchCenter')}
								/>
							</Form.Item>
						</div>
						<div className="flex flex-col md:mb-0 w-full min-w-[170px]">
							<Text className="pl-1">{t('examLanguage')}</Text>
							<Form.Item className="!mb-2" name={'languages'}>
								<Select
									showSearch
									options={examLanguageOptions}
									filterOption={(input, option) =>
										(option?.label ?? '')
											.toLowerCase()
											.includes(input.toLowerCase())
									}
									placeholder={t('searchLanguage')}
								/>
							</Form.Item>
						</div>
						<div className="flex flex-col md:mb-0 w-full min-w-[170px]">
							<Text className="pl-1">{t('abhyasiId')}</Text>
							<Form.Item
								className="!mb-2"
								name={'srcmId'}
								rules={[
									{
										pattern: VALID_ABHYASI_ID_REGEX,
										message: <span>{t('invalidAbhyasiId')}</span>,
									},
								]}
							>
								<Input placeholder="Enter Abhyasi ID" />
							</Form.Item>
						</div>
						<div className="flex flex-col md:mb-0 w-full min-w-[170px]">
							<Text className="pl-1">{t('name')}</Text>
							<Form.Item
								className="!mb-2"
								name={'name'}
								rules={[
									{
										pattern: VALID_NAME_REGEX,
										message: <span>{t('specialCharactersNotAllowed')}</span>,
									},
								]}
							>
								<Input placeholder="Enter Name" />
							</Form.Item>
						</div>
					</div>
				</div>
			</Form>
			<div className="flex flex-row gap-2 md:mb-0">
				<div className="self-start">
					<Button
						className="bg-nav-blue hover:!bg-hover-nav-blue text-white"
						type="primary"
						onClick={handleFilterClick}
					>
						{t('filter')}
					</Button>
				</div>
				<div className="self-start">
					<Button type="default" onClick={handleResetForm}>
						{t('clear')}
					</Button>
				</div>
			</div>
		</div>
	);
};
