import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { useCallApi } from '../../../../hooks/useCallApi';
import {
	GetKanhaBatchesResponse,
	KanhaBatchFilter,
} from '../../../../shared/KanhaInviteTypes';
import { PagingConfig } from '../../../../shared/VolunteerApplicationServiceTypes';
import { DEFAULT_PAGE_SIZE } from '../../../../shared/Constants';
import { getKanhaBatches } from '../../../../service/KanhaInviteService';

export const useKanhaInviteBatches = () => {
	const { srcmId } = useAuth();
	const { callApi } = useCallApi();
	const [kanhaBatchesList, setKanhaBatchesList] =
		useState<GetKanhaBatchesResponse>({
			kanhaBatch: [],
			pagination: { totalNoOfRecords: 0 },
		});
	const [kanhaBatchFilter, setKanhaBatchFilter] = useState<KanhaBatchFilter>(
		{}
	);
	const [kanhaBatchesPaging, setKanhaBatchesPaging] = useState<PagingConfig>({
		pageNo: 1,
		pageSize: DEFAULT_PAGE_SIZE,
	});

	const onKanhaBatchesPageChange = useCallback(
		(pageNumber: number, pageSize: number) => {
			setKanhaBatchesPaging({ pageNo: pageNumber, pageSize });
		},
		[]
	);

	const onKanhaBatchesFilterChange = useCallback(
		(filterParam: KanhaBatchFilter) => {
			setKanhaBatchFilter(filterParam);
			onKanhaBatchesPageChange(
				1,
				kanhaBatchesPaging.pageSize ?? DEFAULT_PAGE_SIZE
			);
		},
		[onKanhaBatchesPageChange, kanhaBatchesPaging.pageSize]
	);

	const fetchKanhaBatchesList = useCallback(
		async (pageNumber: number, pageSize: number, filter: KanhaBatchFilter) => {
			if (srcmId) {
				const pageIndex = pageNumber < 1 ? 0 : pageNumber - 1;
				const kanhaBatchesWithoutKey = await callApi(
					() =>
						getKanhaBatches({
							functionarySrcmId: srcmId,
							pageIndex,
							pageSize,
							kanhaBatchFilter: filter,
						}),
					'errorOccurredWhileLoading'
				);
				const CED = {
					...kanhaBatchesWithoutKey,
					...(kanhaBatchesWithoutKey.kanhaBatch && {
						kanhaBatch: kanhaBatchesWithoutKey.kanhaBatch?.map((batch) => ({
							...batch,
							key: batch.id,
						})),
					}),
				};

				if (
					CED.pagination.totalNoOfPages &&
					CED.pagination.totalNoOfRecords > 0 &&
					pageNumber > CED.pagination.totalNoOfPages
				) {
					onKanhaBatchesPageChange(
						CED.pagination.totalNoOfPages ?? 1,
						pageSize
					);
				} else {
					setKanhaBatchesList(CED);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onKanhaBatchesPageChange, srcmId]
	);

	useEffect(() => {
		void fetchKanhaBatchesList(
			kanhaBatchesPaging.pageNo ?? 1,
			kanhaBatchesPaging.pageSize ?? DEFAULT_PAGE_SIZE,
			kanhaBatchFilter
		);
	}, [kanhaBatchesPaging, kanhaBatchFilter, fetchKanhaBatchesList]);

	return {
		kanhaBatchFilter,
		kanhaBatchesList,
		kanhaBatchesPaging,
		onKanhaBatchesPageChange,
		onKanhaBatchesFilterChange,
	};
};
