import { Button, Table } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CREATE_KANHA_BATCH } from '../../../../shared/Routes';
import { FilterKanhaInviteBatches } from './FilterKanhaInviteBatches';
import { useKanhaInviteBatches } from './useKanhaInviteBatches';
import { tablePaginationConfiguration } from '../../../../shared/Utils';
import { KanhaBatchTableColumns } from './KanhaBatchTableColumns';
import { useExamModuleIds } from '../../../../hooks/useExamModuleIds';

export const ViewKanhaInviteBatches = () => {
	const { t } = useTranslation();
	const { navigateWithoutExamModuleIds } = useExamModuleIds();
	const {
		kanhaBatchFilter,
		kanhaBatchesList,
		kanhaBatchesPaging,
		onKanhaBatchesPageChange,
		onKanhaBatchesFilterChange,
	} = useKanhaInviteBatches();
	const { kanhaBatch, pagination } = kanhaBatchesList;
	const tablePaginationConfig = tablePaginationConfiguration(
		kanhaBatchesPaging,
		pagination,
		onKanhaBatchesPageChange
	);
	const columns = KanhaBatchTableColumns();

	const handleCreateKanhaBatchClick = useCallback(() => {
		navigateWithoutExamModuleIds(CREATE_KANHA_BATCH);
	}, [navigateWithoutExamModuleIds]);

	return (
		<>
			<div className="flex justify-between items-center mb-2.5">
				<h3>{t('kanhaBatchesList')}</h3>
				<Button
					className="bg-nav-blue hover:!bg-hover-nav-blue text-white !rounded-none"
					type="primary"
					size="large"
					onClick={handleCreateKanhaBatchClick}
				>
					{t('createKanhaBatch')}
				</Button>
			</div>
			<FilterKanhaInviteBatches
				value={kanhaBatchFilter}
				onFilterChange={onKanhaBatchesFilterChange}
			/>
			<Table
				dataSource={kanhaBatch}
				columns={columns}
				pagination={tablePaginationConfig}
				scroll={{ x: 'max-content' }}
				className="overflow-x-auto mt-4"
			/>
		</>
	);
};
