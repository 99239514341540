import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ExamFilter } from '../../../../shared/ExamModuleTypes';
import { Button, Form, Select, Typography } from 'antd';
import { SelectedDropdownValue } from '../../../../shared/VolunteerApplicationServiceTypes';
import RegionsDropdown from '../../RegionsDropdown';
import ZonesDropdown from '../../ZonesDropdown';

interface FilterProps {
	value: ExamFilter;
	onFilterChange: (value: ExamFilter) => void;
	examIdInSnap: number;
	languageOptions: Array<{
		value: string;
		label: string;
	}>;
}

interface ExamResultFilterFormType {
	regions?: SelectedDropdownValue;
	zones?: SelectedDropdownValue;
	languages?: string;
}

const { Text } = Typography;

export const FilterGraphs = ({
	value,
	onFilterChange,
	examIdInSnap,
	languageOptions,
}: FilterProps) => {
	const { t } = useTranslation();
	const [form] = Form.useForm<ExamResultFilterFormType>();
	const values = Form.useWatch<ExamResultFilterFormType>([], form);

	const handleFilterClick = useCallback(() => {
		const filterValues: ExamFilter = {
			...values,
			regionIds: [values?.regions?.value] as Array<number>,
			zoneIds: [values?.zones?.value] as Array<number>,
			preferredExaminationLanguages: [values?.languages] as Array<string>,
		};
		const examFilters = { ...value, ...filterValues };
		onFilterChange(examFilters);
	}, [onFilterChange, value, values]);

	const regionsValue: Array<string | number | undefined> = [
		values?.regions?.value,
	];

	const handleResetForm = useCallback(() => {
		form.resetFields();
		form.setFieldsValue({
			regions: undefined,
			zones: undefined,
			languages: undefined,
		});
		const filterValues: ExamFilter = { examId: examIdInSnap };
		onFilterChange(filterValues);
	}, [examIdInSnap, form, onFilterChange]);

	const handleRegionChange = useCallback(() => {
		form.setFieldsValue({
			zones: undefined,
			languages: undefined,
		});
	}, [form]);

	const handleZoneChange = useCallback(() => {
		form.setFieldsValue({
			languages: undefined,
		});
	}, [form]);

	return (
		<div className="flex flex-col">
			<Form
				name="examResultFilterForm"
				layout="horizontal"
				size="middle"
				labelCol={{ span: 24 }}
				wrapperCol={{ span: 24 }}
				autoComplete="off"
				initialValues={value}
				form={form}
			>
				<div className="flex flex-col md:mb-0">
					<div className="flex flex-col gap-4 md:flex-row">
						<div className="flex flex-col md:mb-0 w-full min-w-[170px]">
							<Text className="pl-1">{t('regions')}</Text>
							<Form.Item className="!mb-2" name={'regions'}>
								<RegionsDropdown
									examIdInSnap={examIdInSnap}
									onChange={handleRegionChange}
								/>
							</Form.Item>
						</div>
						<div className="flex flex-col md:mb-0 w-full min-w-[170px]">
							<Text className="pl-1">{t('zones')}</Text>
							<Form.Item className="!mb-2" name={'zones'}>
								<ZonesDropdown
									examIdInSnap={examIdInSnap}
									regionIds={regionsValue}
									onChange={handleZoneChange}
								/>
							</Form.Item>
						</div>
						<div className="flex flex-col md:mb-0 w-full min-w-[170px]">
							<Text className="pl-1">{t('examLanguage')}</Text>
							<Form.Item className="!mb-2" name={'languages'}>
								<Select
									showSearch
									options={languageOptions}
									filterOption={(input, option) =>
										(option?.label ?? '')
											.toLowerCase()
											.includes(input.toLowerCase())
									}
									placeholder={t('searchLanguage')}
								/>
							</Form.Item>
						</div>
					</div>
				</div>
			</Form>
			<div className="flex flex-row gap-2 md:mb-0 mt-2">
				<div className="self-start">
					<Button
						className="bg-nav-blue hover:!bg-hover-nav-blue text-white"
						type="primary"
						onClick={handleFilterClick}
					>
						{t('filter')}
					</Button>
				</div>
				<div className="self-start">
					<Button type="default" onClick={handleResetForm}>
						{t('clear')}
					</Button>
				</div>
			</div>
		</div>
	);
};
