import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	DATE_DO_MMM_YYYY,
	Gender as GENDER,
	VALID_ABHYASI_ID_REGEX,
} from '../../../shared/Constants';
import {
	CancelExamRequest,
	CandidateExamPhaseDetail,
	GetExaminationCenterDetailResponse,
} from '../../../shared/ExamModuleTypes';
import { Gender } from '../../../shared/VolunteerApplicationServiceTypes';
import { useExamRegistration } from './useExamRegistration';
import { useLocation } from 'react-router-dom';
import moment, { utc } from 'moment';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { filterActiveCentersFromExam } from '../../../shared/Utils';

export interface TestCenterSelectionFormType {
	srcmId: string;
	name: string;
	gender: Gender;
	center: string;
	language: string;
	accommodation: string;
	accommodationNo: number;
	specialRequests: string;
	changeOfCenterReason: string;
	reasonForCancellingExam: string;
	candidateExaminationDetailId?: number;
}

const { TextArea } = Input;

export const ExamRegistration = () => {
	const { t } = useTranslation();
	const {
		activeExam,
		fetchExamCenterDetail,
		onSubmitClick,
		onCancelExamClick,
	} = useExamRegistration();
	const { showToastMessage } = useToastMessage();
	const [form] = Form.useForm<TestCenterSelectionFormType>();
	const formValues = Form.useWatch<TestCenterSelectionFormType>([], form);
	const location = useLocation();
	const { candidateExamPhaseDetail } = location.state as {
		candidateExamPhaseDetail: CandidateExamPhaseDetail;
	};
	const { candidateExaminationDetail } = candidateExamPhaseDetail;
	const candidateExam = candidateExaminationDetail.exam;
	const candidateProfile = candidateExaminationDetail.profile;
	const regFormInitialValues = {
		srcmId: candidateProfile.srcmId,
		name: candidateProfile.name,
		gender: candidateProfile.gender,
		center: '',
		language: '',
		accommodation: '',
		specialRequests: '',
	};

	const languageDropdownOptions = activeExam?.examinationLanguageMappings.map(
		(eL) => ({
			value: eL.examinationLanguage.language,
			label: eL.examinationLanguage.language,
		})
	);

	const [accommodation, setAccommodation] = useState('');
	const [disableForm, setDisableForm] = useState<boolean>(false);
	const [centerDetails, setCenterDetails] =
		useState<GetExaminationCenterDetailResponse | null>();
	const [isChangeExam, setIsChangeExam] = useState(false);
	const [isCancelExam, setIsCancelExam] = useState(false);
	const [formHeading, setFormHeading] = useState(t('testCenterSelection'));
	const examCancelled = candidateExaminationDetail.examCancelled;
	const keptOnHold = candidateExaminationDetail.keptHold;
	const candidateRegistered =
		candidateExaminationDetail.registrationStatus === 'REGISTERED';
	const admitCardNumber = candidateExaminationDetail.admitCardNumber;
	const showRegisteredCandidateNote =
		candidateRegistered && !admitCardNumber && !keptOnHold && !examCancelled;

	const initializeForm = useCallback(() => {
		if (
			candidateExamPhaseDetail.registeredForExam &&
			activeExam?.id &&
			activeExam.id === candidateExam.id
		) {
			setDisableForm(activeExam.id === candidateExam.id);
			const newInitialValues = {
				srcmId: candidateProfile.srcmId,
				name: candidateProfile.name,
				gender: candidateProfile.gender,
				center: candidateExaminationDetail.examinationCenter.id.toString(),
				language: candidateExaminationDetail.preferredLanguageForExamination,
				accommodation: candidateExaminationDetail.accommodationRequired
					? t('yes').toUpperCase()
					: t('no').toUpperCase(),
				accommodationNo: candidateExaminationDetail.noOfAccommodations,
				specialRequests: candidateExaminationDetail.specialRequest,
				reasonForCancellingExam:
					candidateExaminationDetail.reasonForCancellingExam,
			};
			form.setFieldsValue(newInitialValues);
		}
	}, [
		activeExam,
		candidateExam,
		candidateExamPhaseDetail,
		candidateExaminationDetail,
		candidateProfile,
		form,
		t,
	]);

	useEffect(() => {
		void initializeForm();
	}, [initializeForm]);

	const handleSubmitClick = useCallback(() => {
		const handleValidation = (callback: () => void) => {
			form
				.validateFields()
				.then(callback)
				.catch(() => showToastMessage('error', t('pleaseEnterValidData')));
		};

		if (candidateExamPhaseDetail.registeredForExam && isChangeExam) {
			const formValuesForChangingExam = {
				...formValues,
				candidateExaminationDetailId: candidateExaminationDetail.id,
			};
			handleValidation(() => onSubmitClick(formValuesForChangingExam, true));
		} else if (!candidateExamPhaseDetail.registeredForExam && !disableForm) {
			handleValidation(() => onSubmitClick(formValues));
		} else {
			const cancelExamRequest: CancelExamRequest = {
				srcmId: formValues.srcmId,
				candidateExaminationDetailId: candidateExaminationDetail.id,
				reasonForCancellingExam: formValues.reasonForCancellingExam,
			};
			handleValidation(() => onCancelExamClick(cancelExamRequest));
		}
	}, [
		candidateExamPhaseDetail.registeredForExam,
		candidateExaminationDetail.id,
		disableForm,
		formValues,
		onSubmitClick,
		onCancelExamClick,
		form,
		showToastMessage,
		isChangeExam,
		t,
	]);

	const onCenterChange = useCallback(
		(value: string) => {
			fetchExamCenterDetail(parseInt(value))
				.then((examCenterDetail) => setCenterDetails(examCenterDetail))
				.catch((error: Error) => {
					showToastMessage('error', error.message);
				});
		},
		[fetchExamCenterDetail, showToastMessage]
	);

	const handleClearClick = useCallback(() => {
		if (isCancelExam) {
			form.setFieldsValue({ reasonForCancellingExam: '' });
		} else {
			form.resetFields();
			setAccommodation('');
			setCenterDetails(null);
		}
	}, [form, isCancelExam]);

	const handleChangeExamCenter = useCallback(() => {
		setFormHeading(t('changeExamCenter'));
		setDisableForm(false);
		setIsChangeExam(true);

		const centerValue = form.getFieldValue('center') as string;
		fetchExamCenterDetail(parseInt(centerValue))
			.then((examCenterDetail) => setCenterDetails(examCenterDetail))
			.catch((error: Error) => {
				showToastMessage('error', error.message);
			});

		const accommodationValue = form.getFieldValue('accommodation') as string;
		setAccommodation(accommodationValue);
	}, [fetchExamCenterDetail, form, showToastMessage, t]);

	const handleCancelExam = useCallback(() => {
		setFormHeading(t('cancelExam'));
		setDisableForm(false);
		setIsCancelExam(true);
	}, [t]);

	const handleBackClick = useCallback(() => {
		if (activeExam?.id) {
			setDisableForm(activeExam.id === candidateExam.id);
		}
		setIsChangeExam(false);
		setIsCancelExam(false);
		setCenterDetails(null);
		form.resetFields();
		void initializeForm();
	}, [activeExam?.id, candidateExam.id, initializeForm, form]);

	useEffect(() => {
		if (examCancelled) {
			setFormHeading(t('examCancelledMessage'));
			setDisableForm(true);
		}
	}, [examCancelled, t]);

	useEffect(() => {
		if (keptOnHold) {
			setFormHeading(t('applicationIsOnHold'));
			setDisableForm(true);
		}
	}, [keptOnHold, t]);

	const examDate = utc(activeExam?.examDate).format(DATE_DO_MMM_YYYY);
	const disableCancelButton = moment().isAfter(activeExam?.examDate);

	return (
		<>
			<div className="w-full font-bold text-3xl py-5 text-center">
				{formHeading}
			</div>
			{keptOnHold && (
				<>
					<div className="w-full font-bold text-lg py-3 px-4 md:px-10">
						{t('holdCandidateNote')}
					</div>
					<div className="w-full font-bold text-xl py-3 px-4 md:px-10">
						{t('onHoldReason')}: {candidateExaminationDetail.onHoldReason}
					</div>
				</>
			)}
			{showRegisteredCandidateNote && (
				<div className="w-full font-bold text-xl py-3 px-4 md:px-10">
					{t('registeredCandidateNote', { examDate })}
				</div>
			)}
			{admitCardNumber && (
				<div className="w-full font-bold text-xl py-3 px-4 md:px-10">
					{t('admitCardNote', { admitCardNumber })}
				</div>
			)}
			<div className="px-4 md:px-10">
				<div className="flex justify-between items-center">
					<div className="flex gap-6">
						<h3>
							{t('examGroup')}: {activeExam?.name}
						</h3>
						<h3>
							{t('examDate')}:{' '}
							{moment(activeExam?.examDate).format(DATE_DO_MMM_YYYY)}
						</h3>
					</div>
				</div>
				<div className="flex justify-between items-center">
					<div className="flex gap-6">
						<h3>
							{t('registrationStartDate')}:{' '}
							{moment(activeExam?.registrationStartDate).format(
								DATE_DO_MMM_YYYY
							)}
						</h3>
						<h3>
							{t('registrationEndDate')}:{' '}
							{utc(activeExam?.registrationEndDate).format(DATE_DO_MMM_YYYY)}
						</h3>
					</div>
				</div>
				<h2 className="mt-5">{t('registrationDetail')}</h2>
				<Form
					name="candidatesFilterForm"
					layout="horizontal"
					size="middle"
					labelCol={{ span: 24 }}
					wrapperCol={{ span: 24 }}
					autoComplete="off"
					form={form}
					disabled={disableForm}
					initialValues={regFormInitialValues}
				>
					<div className="flex flex-col md:flex-row justify-between">
						<div className="flex flex-col md:mb-0">
							<div className="flex flex-wrap gap-4">
								<div className="w-full md:w-1/4">
									<Form.Item
										className="!mb-2"
										label={
											<span className="font-bold text-dark-gray">
												{t('abhyasiId')}
											</span>
										}
										name="srcmId"
										rules={[
											{
												required: true,
												message: <span>{t('required')}</span>,
											},
											{
												pattern: VALID_ABHYASI_ID_REGEX,
												message: <span>{t('invalidAbhyasiId')}</span>,
											},
										]}
									>
										<Input
											size="large"
											placeholder="Enter Abhyasi ID"
											disabled
										/>
									</Form.Item>
								</div>
								<div className="w-full md:w-1/4">
									<Form.Item
										className="!mb-2"
										label={
											<span className="font-bold text-dark-gray">
												{t('name')}
											</span>
										}
										name="name"
										rules={[
											{
												required: true,
												message: <span>{t('required')}</span>,
											},
										]}
									>
										<Input size="large" placeholder="Enter Name" disabled />
									</Form.Item>
								</div>
								<div className="w-full md:w-1/4">
									<Form.Item
										className="!mb-2"
										label={
											<span className="font-bold text-dark-gray">
												{t('gender')}
											</span>
										}
										name="gender"
										rules={[
											{
												required: true,
												message: <span>{t('required')}</span>,
											},
										]}
									>
										<Select
											size="large"
											options={[
												{
													value: GENDER.MALE,
													label: t('male'),
												},
												{
													value: GENDER.FEMALE,
													label: t('female'),
												},
											]}
											disabled
										/>
									</Form.Item>
								</div>
								<div className="w-full md:w-1/4">
									<Form.Item
										className="!mb-2"
										label={
											<span className="font-bold text-dark-gray">
												{t('selectCenter')}
											</span>
										}
										name="center"
										rules={[
											{
												required: true,
												message: <span>{t('required')}</span>,
											},
										]}
									>
										{activeExam && (
											<Select
												size="large"
												options={filterActiveCentersFromExam(activeExam)}
												onChange={onCenterChange}
												disabled={disableForm || isCancelExam}
												showSearch
												filterOption={(input, option) =>
													(option?.label ?? '')
														.toLowerCase()
														.includes(input.toLowerCase())
												}
											/>
										)}
									</Form.Item>
								</div>
								<div className="w-full md:w-1/4">
									<Form.Item
										className="!mb-2"
										label={
											<span className="font-bold text-dark-gray">
												{t('selectLanguage')}
											</span>
										}
										name="language"
										rules={[
											{
												required: true,
												message: <span>{t('required')}</span>,
											},
										]}
									>
										<Select
											size="large"
											options={languageDropdownOptions}
											disabled={disableForm || isCancelExam}
											showSearch
											filterOption={(input, option) =>
												(option?.label ?? '')
													.toLowerCase()
													.includes(input.toLowerCase())
											}
										/>
									</Form.Item>
								</div>
								<div className="w-full md:w-1/4">
									<Form.Item
										className="!mb-2"
										label={
											<span className="font-bold text-dark-gray">
												{t('accommodation')}
											</span>
										}
										name="accommodation"
										rules={[
											{
												required: true,
												message: <span>{t('required')}</span>,
											},
										]}
									>
										<Select
											size="large"
											options={[
												{
													value: t('yes').toUpperCase(),
													label: t('yes').toUpperCase(),
												},
												{
													value: t('no').toUpperCase(),
													label: t('no').toUpperCase(),
												},
											]}
											onChange={(value: string) => {
												setAccommodation(value);
												form.resetFields(['accommodationNo']);
											}}
											disabled={disableForm || isCancelExam}
										/>
									</Form.Item>
								</div>
								<div className="w-full md:w-1/4">
									<Form.Item
										className="!mb-2"
										label={
											<span className="font-bold text-dark-gray">
												{t('howManyAccommodationsAreRequired')}
											</span>
										}
										name="accommodationNo"
										rules={[
											{
												required: accommodation === t('yes').toUpperCase(),
												message: <span>{t('required')}</span>,
											},
										]}
									>
										<InputNumber
											size="large"
											className="w-full"
											min={0}
											onKeyDown={(e) => {
												if (
													e.key !== 'Backspace' &&
													e.key !== 'Tab' &&
													e.key !== 'ArrowLeft' &&
													e.key !== 'ArrowRight' &&
													e.key !== 'Delete' &&
													!/[0-9]/.test(e.key)
												) {
													e.preventDefault();
												}
											}}
											disabled={
												accommodation === t('no').toUpperCase() ||
												disableForm ||
												isCancelExam
											}
										/>
									</Form.Item>
								</div>
								<div className="w-full md:w-1/4">
									<Form.Item
										className="!mb-2"
										label={
											<span className="font-bold text-dark-gray">
												{t('specialRequestsIfAny')}
											</span>
										}
										name="specialRequests"
									>
										<TextArea rows={4} disabled={disableForm || isCancelExam} />
									</Form.Item>
								</div>
								{isChangeExam && (
									<div className="w-full md:w-1/4">
										<Form.Item
											className="!mb-2"
											label={
												<span className="font-bold text-dark-gray">
													{t('reasonForChangingCenter')}
												</span>
											}
											name="changeOfCenterReason"
											rules={[
												{
													required: true,
													message: <span>{t('required')}</span>,
												},
											]}
										>
											<TextArea rows={6} />
										</Form.Item>
									</div>
								)}
								{(isCancelExam || examCancelled) && (
									<div className="w-full md:w-1/4">
										<Form.Item
											className="!mb-2"
											label={
												<span className="font-bold text-dark-gray">
													{t('reasonForCancellingExam')}
												</span>
											}
											name="reasonForCancellingExam"
											rules={[
												{
													required: true,
													message: <span>{t('required')}</span>,
												},
											]}
										>
											<TextArea rows={6} />
										</Form.Item>
									</div>
								)}
							</div>
						</div>
						<div className="flex flex-col items-center md:items-end">
							{centerDetails && (
								<div className="flex gap-4 items-center">
									<h3 className="text-center w-32">
										{t('availableSeats')}{' '}
										<span className="block">{t('atThisCenter')}</span>
									</h3>
									<div className="text-lg px-8 py-2 rounded bg-purple text-white">
										{centerDetails?.noOfSeatsAvailable || 0}
									</div>
								</div>
							)}
							{accommodation === t('yes').toUpperCase() && centerDetails && (
								<div className="flex gap-4 items-center">
									<h3 className="text-center">
										{t('accommodation')}{' '}
										<span className="block">{t('available')}</span>
									</h3>
									<div className="text-lg px-8 py-2 rounded bg-purple text-white">
										{centerDetails?.noOfAccommodationsAvailable || 0}
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="flex flex-row-reverse justify-between items-center">
						{!disableForm && (
							<div className="flex justify-end gap-4">
								{(isCancelExam || isChangeExam) && (
									<Form.Item>
										<Button
											className="w-36 h-12 mb-5 text-base"
											htmlType="reset"
											onClick={handleBackClick}
											disabled={false}
										>
											{t('back')}
										</Button>
									</Form.Item>
								)}
								<Form.Item>
									<Button
										className="w-36 h-12 mb-5 text-base"
										onClick={handleClearClick}
										disabled={false}
									>
										{t('clear')}
									</Button>
								</Form.Item>
								<Form.Item>
									<Button
										className="w-36 h-12 mb-5 bg-nav-blue hover:!bg-hover-nav-blue text-base"
										type="primary"
										htmlType="submit"
										onClick={handleSubmitClick}
										disabled={false}
									>
										{t('submit')}
									</Button>
								</Form.Item>
							</div>
						)}
						{disableForm && !examCancelled && !keptOnHold && (
							<div className="flex justify-end gap-4">
								<Form.Item>
									<Button
										className="w-36 h-12 mb-5 text-base"
										htmlType="reset"
										onClick={handleCancelExam}
										disabled={disableCancelButton || false}
									>
										{t('cancelExam')}
									</Button>
								</Form.Item>
								<Form.Item>
									<Button
										className="w-44 h-12 mb-5 bg-nav-blue hover:!bg-hover-nav-blue text-base"
										type="primary"
										htmlType="submit"
										onClick={handleChangeExamCenter}
										disabled={disableCancelButton || false}
									>
										{t('changeExamCenter')}
									</Button>
								</Form.Item>
							</div>
						)}
						{disableForm && (
							<p className="m-3 ml-0 text-dark-gray text-lg">
								{activeExam?.registrationEndMessage}
							</p>
						)}
					</div>
				</Form>
			</div>
		</>
	);
};
