import { useTranslation } from 'react-i18next';
import { useExamResultsGraph } from './useExamResultsGraph';
import { Select } from 'antd';
import { useSnapshot } from 'valtio';
import { ExamState } from '../../ExamModule.store';
import {
	RESULTS_VIEW_OPTIONS,
	TABLE_VIEW_VALUE,
} from '../../../../shared/Constants';
import { useCallback, useState } from 'react';
import { ResultsGraph } from './ResultsGraph';
import { ResultsTable } from './ResultsTable';
import { ExaminationLanguageMapping } from '../../../../shared/ExamModuleTypes';

export const ExamResults = () => {
	const { t } = useTranslation();
	const [selectedResultView, setSelectedResultView] =
		useState(TABLE_VIEW_VALUE);
	const examSnap = useSnapshot(ExamState);
	const examInSnap = examSnap.selectedExam;
	const { examResult, examFilter, onExamResultFilterChange } =
		useExamResultsGraph();

	const handleResultsViewChange = useCallback(
		(value: string) => {
			onExamResultFilterChange({ examId: examInSnap?.id });
			setSelectedResultView(value);
		},
		[examInSnap?.id, onExamResultFilterChange]
	);

	if (!examInSnap?.resultUploaded) {
		return <h1>{t('resultsNotUploaded')}</h1>;
	}

	const examLanguageOptions = examInSnap.examinationLanguageMappings.map(
		(item: ExaminationLanguageMapping) => ({
			value: item.examinationLanguage.language,
			label: item.examinationLanguage.language,
		})
	);

	const ResultView =
		selectedResultView === TABLE_VIEW_VALUE ? ResultsTable : ResultsGraph;

	return (
		<>
			<div className="flex items-center gap-2">
				<p className="font-medium">{t('viewResultsBy')}</p>
				<Select
					className="w-48"
					options={RESULTS_VIEW_OPTIONS}
					value={selectedResultView}
					onChange={handleResultsViewChange}
				/>
			</div>
			{examResult && examInSnap && (
				<ResultView
					examFilter={examFilter}
					examIdInSnap={examInSnap.id}
					onFilterChange={onExamResultFilterChange}
					result={examResult}
					languageOptions={examLanguageOptions}
				/>
			)}
		</>
	);
};
