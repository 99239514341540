import { useCallback } from 'react';
import { KanhaBatchFilter } from '../../../../shared/KanhaInviteTypes';
import { Button, Form, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useToastMessage } from '../../../../hooks/useToastMessage';

interface FilterProps {
	value: KanhaBatchFilter;
	onFilterChange: (value: KanhaBatchFilter) => void;
}

interface KanhaBatchFilterFormType {
	kanhaBatchName?: string;
}

const { Text } = Typography;

export const FilterKanhaInviteBatches = ({
	value,
	onFilterChange,
}: FilterProps) => {
	const { t } = useTranslation();
	const [form] = Form.useForm<KanhaBatchFilterFormType>();
	const formValues = Form.useWatch<KanhaBatchFilterFormType>([], form);
	const { showToastMessage } = useToastMessage();

	const handleFilterClick = useCallback(() => {
		form
			.validateFields()
			.then(() => {
				const filterValues: KanhaBatchFilter = {
					...formValues,
					kanhaBatchName: formValues?.kanhaBatchName,
				};
				const kanhaBatchFilters = { ...value, ...filterValues };
				onFilterChange(kanhaBatchFilters);
			})
			.catch(() => {
				showToastMessage('error', t('pleaseEnterValidData'));
			});
	}, [onFilterChange, value, formValues, form, showToastMessage, t]);

	const handleResetForm = useCallback(() => {
		form.resetFields();
		form.setFieldsValue({
			kanhaBatchName: undefined,
		});
		const filterValues: KanhaBatchFilter = {};
		onFilterChange(filterValues);
	}, [form, onFilterChange]);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			handleFilterClick();
		}
	};

	return (
		<div className="flex flex-col">
			<Form
				name="kanhaBatchesFilterForm"
				layout="horizontal"
				size="middle"
				labelCol={{ span: 24 }}
				wrapperCol={{ span: 24 }}
				autoComplete="off"
				initialValues={value}
				form={form}
			>
				<div className="flex flex-col md:mb-0">
					<div className="flex flex-col gap-4 md:flex-row">
						<div className="flex flex-col md:mb-0 min-w-[170px]">
							<Text className="pl-1">{t('kanhaBatchName')}</Text>
							<Form.Item className="!mb-2" name={'kanhaBatchName'}>
								<Input placeholder="" onKeyDown={handleKeyDown} />
							</Form.Item>
						</div>
					</div>
				</div>
			</Form>
			<div className="flex flex-row gap-2 md:mb-0">
				<div className="self-start">
					<Button
						className="bg-nav-blue hover:!bg-hover-nav-blue text-white"
						type="primary"
						onClick={handleFilterClick}
					>
						{t('filter')}
					</Button>
				</div>
				<div className="self-start">
					<Button type="default" onClick={handleResetForm}>
						{t('clear')}
					</Button>
				</div>
			</div>
		</div>
	);
};
