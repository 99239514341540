import { useCallback, useEffect, useState } from 'react';
import { SignedUrlDetail } from '../../../../shared/ExamModuleTypes';
import { UploadFile } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useAuth } from '../../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useLoadingIndicator } from '../../../../hooks/useLoadingIndicator';
import { useToastMessage } from '../../../../hooks/useToastMessage';
import {
	getAndDownloadFile,
	getSignedUrl,
	uploadFilesToCloud,
} from '../../../../service/ExamModuleService';
import {
	downloadKanhaBatchCandidateDetails,
	downloadKanhaBatchCandidateDetailsForUploadingResult,
	getCentersOfKanhaBatchCandidates,
	getKanhaBatchCandidateDetails,
	getZonesOfKanhaBatchCandidates,
	importEligibleCandidatesForKanhaBatch,
	uploadKanhaBatchResults,
} from '../../../../service/KanhaInviteService';
import {
	GetKanhaBatchCandidateCentersResponse,
	GetKanhaBatchCandidateDetailsResponse,
	GetKanhaBatchCandidateZonesResponse,
	KanhaBatchFilter,
} from '../../../../shared/KanhaInviteTypes';
import { downloadFile } from '../../../../shared/Utils';
import { AxiosError } from 'axios';
import { useExamModuleIds } from '../../../../hooks/useExamModuleIds';
import { useCallApi } from '../../../../hooks/useCallApi';
import { PagingConfig } from '../../../../shared/VolunteerApplicationServiceTypes';
import { DEFAULT_PAGE_SIZE } from '../../../../shared/Constants';
import { useAddorEditKanhaBatch } from '../batches-list/AddorEditKanhaBatch/useAddorEditKanhaBatch';

export const useKanhaEligibleCandidates = () => {
	const { t } = useTranslation();
	const { srcmId } = useAuth();
	const { setLoading } = useLoadingIndicator();
	const { showToastMessage } = useToastMessage();
	const { callApi } = useCallApi();
	const { kanhaBatchId } = useExamModuleIds();
	const { kanhaBatchById, fetchKanhaBatchById } = useAddorEditKanhaBatch();
	const [kanhaBatchCandidatesList, setKanhaBatchCandidatesList] =
		useState<GetKanhaBatchCandidateDetailsResponse>({
			kanhaBatchCandidateDetails: [],
			pagination: { totalNoOfRecords: 0 },
		});
	const [kanhaBatchFilter, setKanhaBatchFilter] = useState<KanhaBatchFilter>({
		kanhaBatchId,
	});
	const [kanhaBatchCandidatePaging, setKanhaBatchCandidatePaging] =
		useState<PagingConfig>({ pageNo: 1, pageSize: DEFAULT_PAGE_SIZE });
	const [kanhaCandidateCenters, setKanhaCandidateCenters] =
		useState<GetKanhaBatchCandidateCentersResponse>({
			kanhaBatchCandidateCenters: [],
		});
	const [kanhaCandidateZones, setKanhaCandidateZones] =
		useState<GetKanhaBatchCandidateZonesResponse>({
			kanhaBatchCandidateZones: [],
		});

	const fetchKanhaBatchCandidateCenters = useCallback(async () => {
		if (srcmId && kanhaBatchId) {
			try {
				setLoading(true);
				const response = await getCentersOfKanhaBatchCandidates({
					functionarySrcmId: srcmId,
					kanhaBatchId,
				});
				if (response.kanhaBatchCandidateCenters) {
					setKanhaCandidateCenters(response);
				} else {
					setKanhaCandidateCenters({
						kanhaBatchCandidateCenters: [],
					});
				}
			} catch (e) {
				if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', 'Unknown error occurred');
				}
			} finally {
				setLoading(false);
			}
		}
	}, [kanhaBatchId, srcmId, setLoading, showToastMessage]);

	useEffect(() => {
		void fetchKanhaBatchCandidateCenters();
	}, [fetchKanhaBatchCandidateCenters]);

	const fetchKanhaBatchCandidateZones = useCallback(async () => {
		if (srcmId && kanhaBatchId) {
			try {
				setLoading(true);
				const response = await getZonesOfKanhaBatchCandidates({
					functionarySrcmId: srcmId,
					kanhaBatchId,
				});
				if (response.kanhaBatchCandidateZones) {
					setKanhaCandidateZones(response);
				} else {
					setKanhaCandidateZones({
						kanhaBatchCandidateZones: [],
					});
				}
			} catch (e) {
				if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', 'Unknown error occurred');
				}
			} finally {
				setLoading(false);
			}
		}
	}, [kanhaBatchId, srcmId, setLoading, showToastMessage]);

	useEffect(() => {
		void fetchKanhaBatchCandidateZones();
	}, [fetchKanhaBatchCandidateZones]);

	const onKanhaBatchCandidatesPageChange = useCallback(
		(pageNumber: number, pageSize: number) => {
			setKanhaBatchCandidatePaging({ pageNo: pageNumber, pageSize });
		},
		[]
	);

	const onKanhaBatchCandidatesFilterChange = useCallback(
		(filterParam: KanhaBatchFilter) => {
			setKanhaBatchFilter(filterParam);
			onKanhaBatchCandidatesPageChange(
				1,
				kanhaBatchCandidatePaging.pageSize ?? DEFAULT_PAGE_SIZE
			);
		},
		[onKanhaBatchCandidatesPageChange, kanhaBatchCandidatePaging.pageSize]
	);

	const fetchKanhaBatchCandidateDetails = useCallback(
		async (pageNumber: number, pageSize: number, filter: KanhaBatchFilter) => {
			if (srcmId) {
				const pageIndex = pageNumber < 1 ? 0 : pageNumber - 1;
				const KBCDWithoutKey = await callApi(
					() =>
						getKanhaBatchCandidateDetails({
							functionarySrcmId: srcmId,
							pageIndex,
							pageSize,
							kanhaBatchFilter: filter,
						}),
					'errorOccurredWhileLoading'
				);
				const KBCD = {
					...KBCDWithoutKey,
					...(KBCDWithoutKey.kanhaBatchCandidateDetails && {
						kanhaBatchCandidateDetails:
							KBCDWithoutKey.kanhaBatchCandidateDetails.map((candidate) => ({
								...candidate,
								key: candidate.id,
							})),
					}),
				};

				if (
					KBCD.pagination.totalNoOfPages &&
					KBCD.pagination.totalNoOfRecords > 0 &&
					pageNumber > KBCD.pagination.totalNoOfPages
				) {
					onKanhaBatchCandidatesPageChange(
						KBCD.pagination.totalNoOfPages ?? 1,
						pageSize
					);
				} else {
					setKanhaBatchCandidatesList(KBCD);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onKanhaBatchCandidatesPageChange, srcmId]
	);

	const callFetchEligibleCandidatesList = useCallback(() => {
		if (kanhaBatchById?.id && kanhaBatchFilter.kanhaBatchId) {
			void fetchKanhaBatchCandidateDetails(
				kanhaBatchCandidatePaging.pageNo ?? 1,
				kanhaBatchCandidatePaging.pageSize ?? DEFAULT_PAGE_SIZE,
				kanhaBatchFilter
			);
		}
	}, [
		kanhaBatchById,
		kanhaBatchCandidatePaging,
		kanhaBatchFilter,
		fetchKanhaBatchCandidateDetails,
	]);

	useEffect(() => {
		void callFetchEligibleCandidatesList();
	}, [callFetchEligibleCandidatesList]);

	const uploadKanhaEligibleCandidates = useCallback(
		async (
			signedURLDetail: Array<SignedUrlDetail>,
			file: UploadFile<Blob>,
			fileExtension: string | undefined
		) => {
			if (!srcmId) {
				return;
			}
			setLoading(true);
			try {
				const signedURLResponse = await getSignedUrl({
					functionarySrcmId: srcmId,
					signedUrlDetail: signedURLDetail,
				});
				if (
					!signedURLResponse.signedUrlDetail.length ||
					signedURLResponse.signedUrlDetail.length === 0
				) {
					showToastMessage('error', 'Unknown error occurred');
					return;
				}
				// Uploading file to Cloud
				await uploadFilesToCloud(
					file,
					fileExtension,
					signedURLResponse.signedUrlDetail[0].signedUrl
				);
				if (!signedURLResponse.signedUrlDetail[0].objectPath) {
					showToastMessage('error', 'Unknown error occurred');
					return;
				}
				const cloudFileName =
					signedURLResponse.signedUrlDetail[0].objectPath.split('.com/')[1];
				const uploadResponse = await importEligibleCandidatesForKanhaBatch({
					functionarySrcmId: srcmId,
					fileNameInCloudStorage: cloudFileName,
					kanhaBatchFilter,
				});
				if (isEmpty(uploadResponse)) {
					showToastMessage('success', t('candidatesUploadedSuccessfully'));
				} else if (uploadResponse.errorMessage) {
					showToastMessage('error', uploadResponse.errorMessage);
				} else {
					let fileName = 'Errors_File';
					fileName += '.xlsx';
					const blobResponse = await getAndDownloadFile(
						uploadResponse.csvReportSignedUrl
					);
					if (blobResponse) {
						downloadFile(blobResponse, fileName);
					}
					showToastMessage('error', t('resultsUploadErrorMessage'));
				}
				void fetchKanhaBatchCandidateDetails(
					kanhaBatchCandidatePaging.pageNo ?? 1,
					kanhaBatchCandidatePaging.pageSize ?? DEFAULT_PAGE_SIZE,
					kanhaBatchFilter
				);
				void fetchKanhaBatchCandidateCenters();
				void fetchKanhaBatchCandidateZones();
			} catch (e) {
				if (e instanceof AxiosError) {
					// eslint-disable-next-line
					showToastMessage('error', e?.response?.data?.message || e.message);
				} else if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', 'Unknown error occurred');
				}
			} finally {
				setLoading(false);
			}
		},
		[
			kanhaBatchFilter,
			kanhaBatchCandidatePaging,
			fetchKanhaBatchCandidateDetails,
			fetchKanhaBatchCandidateCenters,
			fetchKanhaBatchCandidateZones,
			setLoading,
			showToastMessage,
			srcmId,
			t,
		]
	);

	const downloadKanhaResultsTemplate = useCallback(async () => {
		if (srcmId) {
			setLoading(true);
			try {
				const downloadResponse =
					await downloadKanhaBatchCandidateDetailsForUploadingResult({
						functionarySrcmId: srcmId,
						pageIndex: 1,
						pageSize: 2000,
						kanhaBatchFilter,
					});
				if (downloadResponse.errorMessage) {
					showToastMessage('error', downloadResponse.errorMessage);
					return;
				}
				if (downloadResponse.csvReportSignedUrl) {
					let fileName = 'Results_Template';
					fileName += '.xlsx';
					const blobResponse = await getAndDownloadFile(
						downloadResponse.csvReportSignedUrl
					);
					if (blobResponse) {
						downloadFile(blobResponse, fileName);
					}
				}
			} catch (e) {
				if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', 'Unknown error occurred');
				}
			} finally {
				setLoading(false);
			}
		}
	}, [kanhaBatchFilter, setLoading, showToastMessage, srcmId]);

	const uploadKanhaResults = useCallback(
		async (
			signedURLDetail: Array<SignedUrlDetail>,
			file: UploadFile<Blob>,
			fileExtension: string | undefined
		) => {
			if (!srcmId) {
				return;
			}
			setLoading(true);
			try {
				const signedURLResponse = await getSignedUrl({
					functionarySrcmId: srcmId,
					signedUrlDetail: signedURLDetail,
				});
				if (
					!signedURLResponse.signedUrlDetail.length ||
					signedURLResponse.signedUrlDetail.length === 0
				) {
					showToastMessage('error', 'Unknown error occurred');
					return;
				}
				// Uploading file to Cloud
				await uploadFilesToCloud(
					file,
					fileExtension,
					signedURLResponse.signedUrlDetail[0].signedUrl
				);
				if (!signedURLResponse.signedUrlDetail[0].objectPath) {
					showToastMessage('error', 'Unknown error occurred');
					return;
				}
				const cloudFileName =
					signedURLResponse.signedUrlDetail[0].objectPath.split('.com/')[1];
				const uploadResponse = await uploadKanhaBatchResults({
					functionarySrcmId: srcmId,
					fileNameInCloudStorage: cloudFileName,
					kanhaBatchFilter,
				});
				if (isEmpty(uploadResponse)) {
					showToastMessage('success', t('statusUploadedSuccessfully'));
				} else if (uploadResponse.errorMessage) {
					showToastMessage('error', uploadResponse.errorMessage);
				} else {
					let fileName = 'Errors_File';
					fileName += '.xlsx';
					const blobResponse = await getAndDownloadFile(
						uploadResponse.csvReportSignedUrl
					);
					if (blobResponse) {
						downloadFile(blobResponse, fileName);
					}
					showToastMessage('error', t('resultsUploadErrorMessage'));
				}
			} catch (e) {
				if (e instanceof AxiosError) {
					// eslint-disable-next-line
					showToastMessage('error', e?.response?.data?.message || e.message);
				} else if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', 'Unknown error occurred');
				}
			} finally {
				setLoading(false);
			}
		},
		[kanhaBatchFilter, setLoading, showToastMessage, srcmId, t]
	);

	const exportKanhaEligibleCandidates = useCallback(async () => {
		if (srcmId) {
			setLoading(true);
			try {
				const downloadResponse = await downloadKanhaBatchCandidateDetails({
					functionarySrcmId: srcmId,
					pageIndex: 1,
					pageSize: 2000,
					kanhaBatchFilter,
				});
				if (downloadResponse.errorMessage) {
					showToastMessage('error', downloadResponse.errorMessage);
					return;
				}
				if (downloadResponse.csvReportSignedUrl) {
					let fileName = 'Candidates_Details';
					fileName += '.xlsx';
					const blobResponse = await getAndDownloadFile(
						downloadResponse.csvReportSignedUrl
					);
					if (blobResponse) {
						downloadFile(blobResponse, fileName);
					}
				}
			} catch (e) {
				if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', 'Unknown error occurred');
				}
			} finally {
				setLoading(false);
			}
		}
	}, [kanhaBatchFilter, setLoading, showToastMessage, srcmId]);

	return {
		kanhaBatchById,
		kanhaBatchCandidatesList,
		kanhaBatchCandidatePaging,
		kanhaBatchFilter,
		kanhaCandidateCenters,
		kanhaCandidateZones,
		onKanhaBatchCandidatesFilterChange,
		onKanhaBatchCandidatesPageChange,
		uploadKanhaEligibleCandidates,
		fetchKanhaBatchCandidateDetails,
		fetchKanhaBatchById,
		downloadKanhaResultsTemplate,
		uploadKanhaResults,
		exportKanhaEligibleCandidates,
	};
};
