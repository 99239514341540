import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { useCallApi } from '../../../../hooks/useCallApi';
import {
	createExam,
	getExaminationCenters,
	getExaminationLanguages,
	updateExam,
} from '../../../../service/ExamModuleService';
import {
	Exam,
	ExamForCreation,
	ExamMarkRange,
	GetExaminationCentersResponse,
	GetExaminationLanguagesResponse,
	SectionCategory,
} from '../../../../shared/ExamModuleTypes';
import { ExamCreationFormType } from './ExamCreationFormType';
import { useConfirmationBeforeAction } from '../../../../hooks/useConfirmationBeforeAction';
import { useBatchId } from '../../../../hooks/useBatchId';
import { EXAM_MODULE_EXAMS_LIST } from '../../../../shared/Routes';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useExamModuleIds } from '../../../../hooks/useExamModuleIds';
import { convertDateToUTCString } from '../../../../shared/Utils';
import { useExamsList } from '../useExamsList';
import { useLoadingIndicator } from '../../../../hooks/useLoadingIndicator';
import { useToastMessage } from '../../../../hooks/useToastMessage';

export const useCreateExam = () => {
	const { t } = useTranslation();
	const { srcmId } = useAuth();
	const { setLoading } = useLoadingIndicator();
	const { showToastMessage } = useToastMessage();
	const { confirmBeforeAction } = useConfirmationBeforeAction();
	const { navigateWithBatchId } = useBatchId();
	const { examId } = useExamModuleIds();
	const { callApi } = useCallApi();
	const { examById } = useExamsList({});
	const [examCenters, setExamCenters] = useState<GetExaminationCentersResponse>(
		{
			examinationCenters: [],
			pagination: { totalNoOfRecords: 0 },
		}
	);
	const [examinationLanguagesResponse, setExaminationLanguagesResponse] =
		useState<GetExaminationLanguagesResponse>({
			examinationLanguages: [],
			pagination: { totalNoOfRecords: 0 },
		});

	const fetchExamCenters = useCallback(async () => {
		if (srcmId) {
			const centers = await callApi(
				() =>
					getExaminationCenters({
						functionarySrcmId: srcmId,
						pageIndex: 0,
						pageSize: 2000,
					}),
				'errorOccurredWhileFetchingExamCenters'
			);
			setExamCenters(centers);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [srcmId]);

	useEffect(() => {
		if (srcmId) {
			void fetchExamCenters();
		}
	}, [srcmId, fetchExamCenters]);

	const generateRanges = (
		marks: number,
		rangeString: string
	): ExamMarkRange[] => {
		const [minRange, maxRange] = rangeString.split('-').map(Number); // Convert the range string to numbers
		const step = maxRange - minRange; // Calculate the step size from the range string

		const ranges = [];
		for (let i = 0; i < marks; i += step) {
			ranges.push({
				minimum: i,
				maximum: Math.min(i + step, marks), // Ensure the max value does not exceed the total marks
			});
		}

		return ranges;
	};

	const convertToCategoryArray = useCallback(
		(
			sections: { totalMarksLimit?: number; markLimitRanges?: string }[]
		): SectionCategory[] => {
			return sections.map((section, index) => {
				// Convert string markRanges to array of ExamMarkRange
				const ranges = generateRanges(
					section.totalMarksLimit as number | 0,
					section.markLimitRanges as string | ''
				);

				return {
					sectionName: `Section ${String.fromCharCode(65 + index)}`, // A, B, C, etc.
					totalMarksLimit: section.totalMarksLimit || 0,
					markLimitRanges: ranges,
				};
			});
		},
		[]
	);

	const generateExamForCreation = useCallback(
		(examDetails: ExamCreationFormType) => {
			let examForCreation: ExamForCreation | Exam = {
				name: examDetails.examName,
				registrationStartDate: convertDateToUTCString(
					examDetails.registrationStartDate
				),
				registrationEndDate: convertDateToUTCString(
					examDetails.registrationEndDate,
					true
				),

				examDate: convertDateToUTCString(examDetails.examDate),
				sectionCategories: convertToCategoryArray(examDetails.sections),
				totalMarksLimit: examDetails.totalMarks,
				totalMarkRanges: generateRanges(
					examDetails.totalMarks,
					examDetails.totalMarksRange
				),
				passPercentage: examDetails.passPercentage,
			};
			if (examId) {
				examForCreation.id = examId;
				examForCreation = { ...examById, ...examForCreation };
			}
			return examForCreation;
		},
		[examId, examById, convertToCategoryArray]
	);

	const onConfirmSubmit = useCallback(
		async (examDetails: ExamCreationFormType) => {
			if (examDetails && srcmId) {
				try {
					const exam = generateExamForCreation(examDetails);
					const request = {
						functionarySrcmId: srcmId,
						exam,
						examinationCenterIds: examDetails.examCenters.map(
							(centerId) => centerId
						),
						examinationLanguageIds: examDetails.examLanguages.map(
							(languageId) => languageId
						),
					};
					let response: Exam;
					if (examId > 0) {
						response = await callApi(
							() => updateExam(request),
							'errorOccurredWhileUpdating'
						);
					} else {
						response = await callApi(
							() => createExam(request),
							'errorOccurredWhileCreating'
						);
					}
					const successMessage =
						examId > 0
							? t('updatedExamSuccessfully')
							: t('createdExamSuccessfully');
					if (response) {
						Modal.success({
							title: t('successful'),
							content: successMessage,
							afterClose: () => {
								navigateWithBatchId(EXAM_MODULE_EXAMS_LIST);
								return;
							},
							okButtonProps: {
								className: 'bg-nav-blue hover:!bg-hover-nav-blue',
							},
						});
					}
				} catch (e) {
					// handled in callApi
				}
			}
		},
		[navigateWithBatchId, callApi, srcmId, t, generateExamForCreation, examId]
	);

	const onSubmitClick = useCallback(
		(examDetails: ExamCreationFormType) => {
			const confirmationMessage =
				examId > 0
					? t('examUpdationConfirmMessage')
					: t('examCreationConfirmMessage');
			confirmBeforeAction(
				'confirmSubmit',
				confirmationMessage,
				() => onConfirmSubmit(examDetails),
				true
			);
		},
		[confirmBeforeAction, onConfirmSubmit, t, examId]
	);

	const fetchLanguages = useCallback(async () => {
		if (srcmId) {
			try {
				setLoading(true);
				const response = await getExaminationLanguages({
					functionarySrcmId: srcmId,
					pageIndex: 0,
					pageSize: 2000,
					ExaminationLanguageFilter: {
						status: ['ACTIVE'],
					},
				});
				setExaminationLanguagesResponse(response);
			} catch (e) {
				if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', 'Unknown error occurred');
				}
			} finally {
				setLoading(false);
			}
		}
	}, [setLoading, showToastMessage, srcmId]);

	useEffect(() => {
		if (srcmId) {
			void fetchLanguages();
		}
	}, [fetchLanguages, srcmId]);

	return {
		examId,
		examById,
		examCenters,
		examinationLanguagesResponse,
		onSubmitClick,
	};
};
