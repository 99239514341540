import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Typography } from 'antd';
import {
	VALID_ABHYASI_ID_REGEX,
	VALID_NAME_REGEX,
} from '../../../shared/Constants';
import { Exam, ExamFilter } from '../../../shared/ExamModuleTypes';
import { useToastMessage } from '../../../hooks/useToastMessage';

interface FilterProps {
	value: ExamFilter;
	onFilterChange: (value: ExamFilter) => void;
	exambyId: Exam;
	disableFilters?: boolean;
}

const { Text } = Typography;

interface CandidatesFilterFormType {
	srcmId?: string;
	name?: string;
}

export const FilterHeldCandidates = ({
	value,
	onFilterChange,
	exambyId,
	disableFilters = false,
}: FilterProps) => {
	const { t } = useTranslation();
	const { showToastMessage } = useToastMessage();
	const [form] = Form.useForm<CandidatesFilterFormType>();
	const values = Form.useWatch<CandidatesFilterFormType>([], form);

	const handleFilterClick = useCallback(() => {
		form
			.validateFields()
			.then(() => {
				const filterValues: ExamFilter = {
					...values,
					srcmId: values?.srcmId,
					name: values?.name,
				};
				const examFilters = { ...value, ...filterValues };
				onFilterChange(examFilters);
			})
			.catch(() => {
				showToastMessage('error', t('pleaseEnterValidData'));
			});
	}, [onFilterChange, value, values, form, showToastMessage, t]);

	const handleResetForm = useCallback(() => {
		form.resetFields();
		const filterValues: ExamFilter = {
			examId: exambyId.id,
			keptHold: true,
		};
		onFilterChange(filterValues);
	}, [exambyId, form, onFilterChange]);

	return (
		<div className="flex flex-col">
			<Form
				name="candidatesFilterForm"
				layout="horizontal"
				size="middle"
				labelCol={{ span: 24 }}
				wrapperCol={{ span: 24 }}
				autoComplete="off"
				initialValues={value}
				form={form}
				disabled={disableFilters}
			>
				<div className="flex flex-col md:mb-0">
					<div className="flex flex-col gap-4 md:flex-row">
						<div className="flex flex-col md:mb-0 w-full min-w-[170px]">
							<Text className="pl-1">{t('abhyasiId')}</Text>
							<Form.Item
								className="!mb-2"
								name={'srcmId'}
								rules={[
									{
										pattern: VALID_ABHYASI_ID_REGEX,
										message: <span>{t('invalidAbhyasiId')}</span>,
									},
								]}
							>
								<Input placeholder="Enter Abhyasi ID" />
							</Form.Item>
						</div>
						<div className="flex flex-col md:mb-0 w-full min-w-[170px]">
							<Text className="pl-1">{t('name')}</Text>
							<Form.Item
								className="!mb-2"
								name={'name'}
								rules={[
									{
										pattern: VALID_NAME_REGEX,
										message: <span>{t('specialCharactersNotAllowed')}</span>,
									},
								]}
							>
								<Input placeholder="Enter Name" />
							</Form.Item>
						</div>
					</div>
				</div>
			</Form>
			<div className="flex flex-row gap-2 md:mb-0">
				<div className="self-start">
					<Button
						className="bg-nav-blue hover:!bg-hover-nav-blue text-white"
						type="primary"
						onClick={handleFilterClick}
					>
						{t('filter')}
					</Button>
				</div>
				<div className="self-start">
					<Button type="default" onClick={handleResetForm}>
						{t('clear')}
					</Button>
				</div>
			</div>
		</div>
	);
};
